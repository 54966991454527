<template>
    <approvals-list-card-section v-if="null !== price" :label="t('label')" :text="price" :css-class="cssClass"/>
</template>

<script>

    import {get} from "lodash";
    import clm from "country-locale-map";

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardPrice",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: String
        },
        data() {

            return {
                t_path: "components.approvals.list.card.price"
            };
        },
        computed: {
            price() {

                if (this.approval.order.cost && 1 === parseInt(process.env.VUE_APP_VIEW_APPROVAL_ORDER_PRICE)) {

                    const country = get(this.approval.order, "country", this.$store.getters["user/profile/country"]);

                    // Currency format local must be calculated based on order selected country
                    return new Intl
                        .NumberFormat(
                            clm.getLocaleByAlpha3(country).replace(/_/ig, "-") + "-u-nu-latn",
                            {
                                style: "currency",
                                currency: get(this.approval.order, "currency", "USD")
                            }
                        )
                        .format(this.approval.order.cost);
                }

                return null;
            }
        }
    }
</script>