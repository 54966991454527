export default {
    required: "Wartość jest wymagana",
    email: "Wartość nie jest prawidłowym adresem e-mail",
    mask: "Wartość jest nieprawidłowa",
    maxValue: "Maksymalna wartość to {maxValue}",
    regex: "Wartość jest nieprawidłowa",
    server: {
        DELEGATES_NOT_ENABLED: "Delegaci nie są włączeni dla tego partnera.",
        DELEGATE_DOES_NOT_EXIST: "Delegat nie istnieje",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Nie możesz aktualizować delegata, który nie należy do Ciebie.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Nie możesz usunąć delegata, który do Ciebie nie należy.",
        DELEGATE_ALREADY_EXISTS: "Istniejąca reguła delegata spełniająca te kryteria już istnieje.",
        DELEGATE_CANNOT_BE_SELF: "Nie możesz dodać siebie jako delegata.",
        IMPERSONATE_USER_NO_PERMISSIONS: "Użytkownik {email} nie ma dostępu do DXC Gear i nie można składać zamówień w jego imieniu",
        INVALID_USER_TYPE: "Typ użytkownika nie jest pracownikiem",
        INVALID_EMPLOYMENT_STATUS: "Użytkownik nie jest aktywnym pracownikiem",
        USER_NOT_FOUND: "Użytkownik nie został znaleziony",
        "Invalid user type": "Nieprawidłowy typ użytkownika",
        "User not found": "Użytkownik nie został znaleziony"
    }
}