export default {
    required: "必填项",
    email: "值不是有效的电子邮件地址",
    mask: "值无效",
    maxValue: "最大值为 {maxValue}",
    regex: "值无效",
    server: {
        DELEGATES_NOT_ENABLED: "未为此合作伙伴启用代表功能。",
        DELEGATE_DOES_NOT_EXIST: "委托人不存在",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "无法更新不属于您的委托人。",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "无法删除不属于您的代表。",
        DELEGATE_ALREADY_EXISTS: "符合此条件的现有委托规则已存在。",
        DELEGATE_CANNOT_BE_SELF: "您不能将自己添加为代表。",
        IMPERSONATE_USER_NO_PERMISSIONS: "用户 {email} 无权访问 DXC Gear，因此无法代表他们进行订购",
        INVALID_USER_TYPE: "用户类型不是员工",
        INVALID_EMPLOYMENT_STATUS: "用户不是现任员工",
        USER_NOT_FOUND: "未找到用户",
        "Invalid user type": "用户类型无效",
        "User not found": "未找到用户"
    }
}