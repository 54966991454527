<template>
    <approvals-list-card-section class="mb-n3" :label="t('label')" :html="html" :css-class="cssClass"/>
</template>

<script>

    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardDetails",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                default: () => "col-12"
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.card.details"
            };
        },
        computed: {
            html() {

                return sanitize(
                    getTranslationValue(this.approval.order, "details", this.$i18n)
                );
            }
        }
    }
</script>