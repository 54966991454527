export default {
    required: "A value is required",
    email: "Value is not a valid email address",
    mask: "The value is invalid",
    maxValue: "The maximum value is {maxValue}",
    regex: "The value is invalid",
    server: {
        DELEGATES_NOT_ENABLED: "Delegates are not enabled for this partner.",
        DELEGATE_DOES_NOT_EXIST: "Delegate does not exist",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Cannot update a delegate that does not belong to you.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Cannot delete a delegate that does not belong to you.",
        DELEGATE_ALREADY_EXISTS: "An existing delegate rule matching this criteria already exists.",
        DELEGATE_CANNOT_BE_SELF: "You cannot add yourself as a delegate.",
        IMPERSONATE_USER_NO_PERMISSIONS: "User {email} does not have access to DXC Gear and no ordering can be done on their behalf",
        INVALID_USER_TYPE: "User type is not an employee",
        INVALID_EMPLOYMENT_STATUS: "User is not an active employee",
        USER_NOT_FOUND: "User not found",
        "Invalid user type": "Invalid user type",
        "User not found": "User not found"
    }
}