<template>
    <layout-two-column>
        <template #column>
            <panel>
                <orders-history/>
            </panel>

            <panel v-if="true === permissions.support" class="mt-4">
                <orders-question/>
            </panel>
        </template>

        <template #default>
            <loading v-if="loading"/>
            <div v-else>
                <!-- Home page partner message -->
                <home-message/>

                <views-home-type-products v-if="type === types.products && null !== groupingType"
                                          :grouping="groupingType"
                                          :categories="categories"
                                          :catalog-message="catalogMessage"/>

                <views-home-type-categories v-if="type === types.categories && null !== groupingType"
                                            :grouping="groupingType"
                                            :categories="categories"
                                            :catalog-message="catalogMessage"/>

                <products-list v-if="products"
                               class="mt-3"
                               :value="products.items"
                               :grouping="groupingType"
                               :grouping-id="products.groupingId"/>
            </div>
        </template>
    </layout-two-column>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixin_view from "@/mixins/view";
    import mixin_catalog from "@/mixins/catalog";
    import mixin_widget from "@mixins_widget";
    import emitter, {EVENTS} from "@/api/events";
    import {CATALOG_STATUS_MESSAGE, CATALOG_ROOT_CATEGORY, HOME_PAGE_GROUPING_TYPE} from "@/constants";

    import AdvisorRecommendations from "@/components/advisor/AdvisorRecommendations";
    import HomeMessage from "@components_home_HomeMessage";
    import LayoutTwoColumn from "@/components/layout/LayoutTwoColumn";
    import Loading from "@/components/Loading";
    import OrdersHistory from "@/components/orders/OrdersHistory";
    import OrdersQuestion from "@/components/orders/OrdersQuestion";
    import Panel from "@/components/Panel";
    import ProductsList from "@/components/products/ProductsList";
    import ViewsHomeTypeCategories from "@/views/home/ViewsHomeTypeCategories";
    import ViewsHomeTypeProducts from "@/views/home/ViewsHomeTypeProducts";

    export default {
        name: "ViewsHome",
        mixins: [mixin_view, mixin_catalog, mixin_widget],
        components: {
            AdvisorRecommendations,
            HomeMessage,
            LayoutTwoColumn,
            Loading,
            OrdersHistory,
            OrdersQuestion,
            Panel,
            ProductsList,
            ViewsHomeTypeCategories,
            ViewsHomeTypeProducts
        },
        data() {

            return {
                t_path: "views.home",
                loading: false,
                types: {
                    products: "products",
                    categories: "categories"
                },
                type: process.env.VUE_APP_HOME_PAGE_TYPE,
                products: null,
                catalogMessageSource: null
            }
        },
        computed: {
            ...mapGetters({
                groupingType: "grouping/type"
            }),
            categories() {

                return this.catalog_getCategories();
            },
            permissions() {

                return {
                    support: null === this.$store.getters["user/impersonatorToken"]
                };
            },
            catalogMessage() {

                if (CATALOG_STATUS_MESSAGE) {

                    return this.catalogMessageSource
                        ? this.$t(`catalog.message.${this.catalogMessageSource.message}`, {
                            equipmentQuizLink: this.$router.resolve({name: "quiz", query: {reset: true}}).fullPath,
                            widgetOptions: this.widget_optionsJson,
                            supportEmail: this.catalogMessageSource.email
                        })
                        : null;
                }

                return 0 === this.categories.length && (!this.products || 0 === this.products.items.length)
                    ? this.t("noAvailableItems", {
                        widgetOptions: this.widget_optionsJson
                    })
                    : null
            }
        },
        methods: {
            fetch() {

                if (!this.constraints_hasUserAvailableProducts()) {

                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.loading = true;

                const promises = [this.catalog_fetchCategories({type: HOME_PAGE_GROUPING_TYPE})];

                if (CATALOG_STATUS_MESSAGE) {

                    promises.push(this.$store.dispatch("user/catalog/getCatalogMessage"))
                }

                return Promise
                    .all(promises)
                    .then(responses => {

                        const [catalog, catalogMessage] = responses;

                        this.catalogMessageSource = catalogMessage && catalogMessage.message
                            ? catalogMessage
                            : null;

                        this.catalog_logActivity();

                        if (true === CATALOG_ROOT_CATEGORY) {

                            return this.$platform
                                .get(`/api/v2/grouping/${this.groupingType}/${this.$store.getters["grouping/rootItem"].id}?hiearchy_search=false`)
                                .then(response => {

                                    this.products = {
                                        groupingId: response.data.results._id,
                                        items: response.data.results.products
                                    };
                                });
                        }
                    })
                    .finally(() => this.loading = false);
            },
            refreshProducts() {

                if (this.products) {

                    this.$platform
                        .get(`/api/v2/grouping/${this.groupingType}/${this.products.groupingId}?hiearchy_search=false`)
                        .then(response => this.products.items = response.data.results.products);
                }
            }
        },
        mounted() {

            this.fetch();

            emitter.on(EVENTS.USER_CONSTRAINTS_FETCH, this.refreshProducts);
        },
        beforeUnmount() {

            emitter.off(EVENTS.USER_CONSTRAINTS_FETCH, this.refreshProducts);
        }
    }
</script>