<template>
    <approvals-list-card-section :label="t('label')" :text="date" :css-class="cssClass"/>
</template>

<script>

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardDate",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: String
        },
        data() {

            return {
                t_path: "components.approvals.list.card.date"
            };
        },
        computed: {
            date() {

                return this.d(this.approval.order.date, "date");
            }
        }
    }
</script>