export default {
    title: {
        new: "Adicionar novo endereço",
        complete: "Endereço Completo",
        edit: "Editar Endereço"
    },
    incomplete: "Por favor, preencha os campos de endereço obrigatórios",
    info: {
        "CHN": "Por favor, digite seu endereço em chinês.</br> Por favor, digite seu endereço em chinês.",
        "BGR": "Digite seu <b>endereço residencial</b> em inglês para receber seus itens.",
        "PRI": "Insira seu endereço residencial, não uma caixa postal, para receber pacotes.",
        "SGP": "Por favor, inclua o número da sua unidade no seu endereço.",
        "SVK": "Por favor, insira seu endereço em <b>inglês</b>",
        "POL": "Por favor, insira seu endereço em <b>inglês</b>"
    },
    model: {
        country: {
            label: "País"
        }
    },
    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nome"
                },
                "placeholder": {
                    "Name": "Nome",
                    "Name in English": "Nome em inglês"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "E-mail pessoal"
                },
                placeholder: {
                    "Personal Email": "E-mail pessoal"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-mail do gerente"
                },
                placeholder: {
                    "Manager Email": "E-mail do gerente"
                }
            },
            managerName: {
                label: {"Manager Name": "Nome do gerente"},
                placeholder: {"Manager Name": "Nome do gerente"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "CEP",
                    "Post Code": "Código Postal",
                    "Postal Code": "Código postal",
                    "CPA": "Contador"
                },
                "placeholder": {
                    "Zipcode": "CEP",
                    "Post Code": "Código Postal",
                    "Postal Code": "Código postal",
                    "CPA": "Contador"
                }
            },
            "province": {
                "label": {
                    "Province": "Província",
                    "Prefecture": "Prefeitura",
                    "Province/Region": "Província/Região",
                    "Province Code": "Código da Província"
                },
                "placeholder": {
                    "Province": "Província",
                    "Prefecture": "Prefeitura",
                    "Province/Region": "Província/Região",
                    "Province Code": "Código da Província"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Número de telefone"
                },
                "placeholder": {
                    "Phone Number": "Número de telefone"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Rua",
                    "Tax ID": "Número de identificação fiscal"
                },
                "placeholder": {
                    "Street": "Rua",
                    "Street in English": "Rua em inglês",
                    "Tax ID (RUT)": "Número de identificação fiscal (RUT)",
                    "Number/Street/Apartment": "Número/Rua/Apartamento"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Distrito",
                    "Colonia": "Colônia",
                    "Canton": "Cantão",
                    "Street": "Rua"
                },
                "placeholder": {
                    "District": "Distrito",
                    "Colonia": "Colônia",
                    "Canton": "Cantão",
                    "Number/Street/Apartment": "Número/Rua/Apartamento"
                }
            },
            "city": {
                "label": {
                    "City": "Cidade",
                    "Town/City/Locality": "Cidade/Localidade",
                    "Town/City": "Cidade/Cidade",
                    "City/Municipality/Locality": "Cidade/Município/Localidade",
                    "City/Town/Locality": "Cidade/Vila/Localidade",
                    "City/Town": "Cidade/Vila",
                    "District": "Distrito",
                    "City/Town/Locallity": "Cidade/Vila/Localidade",
                    "Town/City/Sub Locality": "Cidade/Sublocalidade"
                },
                "placeholder": {
                    "City": "Cidade",
                    "Town/City": "Cidade/Cidade",
                    "Town/City/Locality": "Cidade/Localidade",
                    "City/Municipality/Locality": "Cidade/Município/Localidade",
                    "City/Town/Locality": "Cidade/Vila/Localidade",
                    "City/Town in English": "Cidade/Vila em Inglês",
                    "District": "Distrito",
                    "City/Town/Locallity": "Cidade/Vila/Localidade",
                    "Town/City/Sub Locality": "Cidade/Sublocalidade"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                },
                placeholder: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                }
            },
            "region": {
                "label": {
                    "Region": "Região",
                    "County": "Condado",
                    "Locality": "Localidade"
                },
                "placeholder": {
                    "Region": "Região",
                    "County": "Condado",
                    "Locality": "Localidade"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "Caixa postal"
                },
                "placeholder": {
                    "PO BOX": "Caixa postal"
                }
            },
            state: {
                "label": {
                    "State": "Estado",
                    "Province/Region": "Província/Região",
                    "Province": "Província",
                    "Department": "Departamento",
                    "Municipality/Comuna": "Município/Comuna"
                },
                "placeholder": {
                    "State": "Estado",
                    "Province/Region": "Província/Região",
                    "Province": "Província",
                    "Department": "Departamento",
                    "Municipality/Comuna": "Município/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alasca",
                    "Arizona": "Arizona",
                    "Arkansas": "Arcansas",
                    "California": "Califórnia",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distrito de Columbia",
                    "Florida": "Flórida",
                    "Georgia": "Geórgia",
                    "Hawaii": "Havaí",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Luisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississipi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebrasca",
                    "Nevada": "Nevada",
                    "New Hampshire": "Nova Hampshire",
                    "New Jersey": "Nova Jersey",
                    "New Mexico": "Novo México",
                    "New York": "Nova Iorque",
                    "North Carolina": "Carolina do Norte",
                    "North Dakota": "Dakota do Norte",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pensilvânia",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Carolina do Sul",
                    "South Dakota": "Dakota do Sul",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virgínia",
                    "Washington": "Washington",
                    "West Virginia": "Virgínia Ocidental",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Instruções de entrega"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Informações extras de endereço, instruções de entrega, etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Informações extras de endereço, instruções de entrega, etc. em inglês ",
                    "Extra address information, delivery instructions, etc": "Informações extras de endereço, instruções de entrega, etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Nome completo"},
                placeholder: {"Full name": "Nome completo"}
            },
            firstStreet: {
                label: {"Address 1": "Endereço 1"},
                placeholder: {"Address 1": "Rua + número da casa/apartamento"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Endereço 2",
                    "District": "Distrito"
                },
                placeholder: {
                    "Address 2": "Rua + número da casa/apartamento",
                    "District": "Distrito"
                }
            },
            city: {
                label: {City: "Cidade"},
                placeholder: {City: "Cidade"},
            },
            state: {
                label: {State: "Estado"},
                placeholder: {State: "Estado"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "CEP",
                    "Post Code": "Código Postal",
                    "Postal Code": "Código postal",
                    "CPA": "Contador"
                },
                "placeholder": {
                    "Zipcode": "CEP",
                    "Post Code": "Código Postal",
                    "Postal Code": "Código postal",
                    "CPA": "Contador"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Número de identificação fiscal"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Identificação Fiscal (CDI, CUIT, CUIL, etc.)"
                }
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "Estado/Território"},
                options: {
                    "Andaman and Nicobar Islands": "Ilhas Andaman e Nicobar",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chattisgarh-Cidade",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra e Nagar Haveli e Damão e Diu",
                    "Delhi": "Délhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu and Kashmir": "Jammu e Caxemira",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Querala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagalândia",
                    "Odisha": "Orissa",
                    "Puducherry": "Puducherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajastão",
                    "Sikkim": "Siquim",
                    "Tamil Nadu": "Tâmil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Utar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "Bengala Ocidental",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} é obrigatório.",
        mask: "{field} é inválido."
    },
    buttons: {
        submit: {
            text: "Salvar",
            saving: "Salvando"
        },
        cancel: {
            text: "Cancelar"
        }
    },
    disclaimer: "Adicionar este endereço exigirá verificação de e-mail. Após salvar o endereço, verifique seu e-mail e clique no link dentro dele.",
    message: {
        added: "Endereço foi adicionado",
        updated: "O endereço foi atualizado"
    }
}