export default {
    title: "Orden en nombre de",
    model: {
        email: {
            label: "Correo electrónico",
            placeholder: "Por favor, rellene el correo electrónico del usuario",
            errors: {
                myself: "El correo electrónico debe ser diferente al suyo"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Entregar",
            processing: "Sumisión"
        }
    },
    error: {
        userNotExists: "{email} no existe",
        country: {
            default: "OI actualmente no está disponible en el país del usuario",
            ALPHA3: ""
        }
    }
}