import {order} from "@/api/order";
import {retryRequest} from "@/helpers/request";
import emitter, {EVENTS} from "@/api/events";

const format = items => items.map(item => ({...item, id: item._id}))

export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
    },
    getters: {
        loading: state => state.loading,
        items: state => state.items,
        products: state => state.items.reduce((result, order) => result.concat(order.products), [])
    },
    mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setItems: (state, items) => state.items = items,
        addItem: (state, item) => state.items.push(item),
    },
    actions: {
        fetch(context, params) {

            context.commit("setLoading", true);

            return retryRequest(() => order.get(`/users/${this.getters["user/id"]}/orders`, {params}))
                .then(response => {

                    context.commit("setItems", format(response.data.results));
                    context.commit("setLoading", false);
                });
        },
        loadItems(context, params) {

            return order
                .get(`/users/${this.getters["user/id"]}/items`, {params})
                .then(response => ({items: response.data.results, total: response.data.pagination.totalCount}));
        },
        post(context, model) {

            return retryRequest(
                () => order.post(`/users/${this.getters["user/id"]}/orders`, model),
                [1000, 2000],
                error => !error.response || (409 !== error.response.status && 400 !== error.response.status)
            ).then(result => {

                emitter.emit(EVENTS.USER_ORDER_SUBMIT);

                return result;
            });
        },
        cancel(context, model) {

            return retryRequest(
                () => order.put(`/users/${this.getters["user/id"]}/items/${model.id}/cancel`, model),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_CANCEL));
        },
        received(context, orderProductId) {

            return retryRequest(
                () => order.put(`/users/${this.getters["user/id"]}/items/${orderProductId}/received`),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_RECEIVED))
        },
        notReceived(context, orderProductId) {

            return retryRequest(
                () => order.put(`/users/${this.getters["user/id"]}/items/${orderProductId}/not-received`),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_NOT_RECEIVED))
        },
        undo(context, orderProductId) {

            return retryRequest(
                () => order.post(`/users/${this.getters["user/id"]}/items/${orderProductId}/status/undo`),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_UNDO))
        },
        hide(context, orderProductId) {

            return retryRequest(
                () => order.post(`/users/${this.getters["user/id"]}/items/${orderProductId}/archive`),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_HIDE))
        },
        transfer(context, {orderProductId, email, reason}) {

            return retryRequest(
                () => order.put(`/users/${this.getters["user/id"]}/items/${orderProductId}/transfer`, {email, reason}),
                [1000, 2000]
            ).then(() => emitter.emit(EVENTS.USER_ORDER_TRANSFER))
        },
        getRequirements(context, {items, country}) {

            return retryRequest(
                () => order.post(`/users/${this.getters["user/id"]}/orders/requirements`, {
                    country,
                    items: items.map(item => ({sku: item.sku, quantity: item.quantity}))
                }),
                [1000, 2000]
            ).then(response => response.data.results.tags)
        }
    }
}