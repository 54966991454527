<template>
    <div class="col-12">
        <div class="row">
            <approvals-list-card-section v-for="(field, index) in fields"
                                         :css-class="'col-12'"
                                         :key="index"
                                         :label="field.label">
                <approvals-list-card-editable-decorator :field="field"
                                                        :approval="approval"
                                                        :filter-decorator-dependency="true">
                    {{field.value}}
                </approvals-list-card-editable-decorator>
            </approvals-list-card-section>
        </div>
    </div>
</template>

<script>

    import ApprovalsListCardEditableDecorator from "@/components/approvals/list/card/editable/ApprovalsListCardEditableDecorator";
    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardHistoryApprovalsCardFields",
        components: {
            ApprovalsListCardEditableDecorator,
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            config: {
                type: Array,
                required: true
            }
        },
        computed: {
            fields() {

                return this.config.reduce(
                    (result, field) => {

                        if (this.approval.dynamic[field.payload.field]) {

                            result.push({
                                ...field,
                                value: this.approval.dynamic[field.payload.field]
                            });
                        }

                        return result;
                    },
                    []
                );
            }
        }
    }
</script>