export default {
    title: "Bestellung im Auftrag von",
    model: {
        email: {
            label: "E-Mail",
            placeholder: "Bitte geben Sie die Benutzer-E-Mail ein",
            errors: {
                myself: "Die E-Mail-Adresse muss sich von Ihrer"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Einreichen",
            processing: "Einreichen"
        }
    },
    error: {
        userNotExists: "{email} existiert nicht",
        country: {
            default: "OI ist derzeit im Benutzerland nicht verfügbar",
            ALPHA3: ""
        }
    }
}