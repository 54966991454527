export default {
    title: "Bestelling namens",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Vul het e-mailadres van de gebruiker in",
            errors: {
                myself: "E-mailadres moet verschillen van uw"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Indienen",
            processing: "Verzenden"
        }
    },
    error: {
        userNotExists: "{email} bestaat niet",
        country: {
            default: "OI is momenteel niet beschikbaar in het land van de gebruiker",
            ALPHA3: ""
        }
    }
}