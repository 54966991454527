<template>
    <div class="pb-3 approvals-list-card" :class="cssClass">
        <div class="small text-black-50 pb-1">{{label}}</div>

        <div class="value">
            <slot>
                <div v-if="text">{{text}}</div>

                <div v-if="html" v-html="html"></div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ApprovalsListCardSection",
        props: {
            label: {
                type: String,
                required: true
            },
            cssClass: {
                type: String,
                default: "col-6"
            },
            html: String,
            text: String
        }
    }
</script>