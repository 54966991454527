export default {
    title: {
        new: "Új cím hozzáadása",
        complete: "Teljes cím",
        edit: "Cím szerkesztése"
    },
    incomplete: "Kérjük, töltse ki a kötelező címmezőket",
    info: {
        "CHN": "Kérjük, adja meg címét kínaiul.</br> Kérjük, adja meg címét kínaiul.",
        "BGR": "Írja be <b>otthoni címét</b> angolul, hogy megkapja a termékeket.",
        "PRI": "A csomagok átvételéhez kérjük, adja meg otthoni címét, ne postafiókot.",
        "SGP": "Kérjük, adja meg az egységszámát a címben.",
        "SVK": "Kérjük, írja be a címét <b>angolul</b>",
        "POL": "Kérjük, adja meg címét <b>angolul</b>"
    },
    model: {
        country: {
            label: "Ország"
        }
    },
    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Név"
                },
                "placeholder": {
                    "Name": "Név",
                    "Name in English": "Név angolul"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Személyes e-mail"
                },
                placeholder: {
                    "Personal Email": "Személyes e-mail"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Vezető e-mail címe"
                },
                placeholder: {
                    "Manager Email": "Vezető e-mail címe"
                }
            },
            managerName: {
                label: {"Manager Name": "Menedzser neve"},
                placeholder: {"Manager Name": "Menedzser neve"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Irányítószám",
                    "Post Code": "Irányítószám",
                    "Postal Code": "Irányítószám",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Irányítószám",
                    "Post Code": "Irányítószám",
                    "Postal Code": "Irányítószám",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Tartomány",
                    "Prefecture": "Prefektúra",
                    "Province/Region": "Tartomány/régió",
                    "Province Code": "Tartománykód"
                },
                "placeholder": {
                    "Province": "Tartomány",
                    "Prefecture": "Prefektúra",
                    "Province/Region": "Tartomány/régió",
                    "Province Code": "Tartománykód"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Telefonszám"
                },
                "placeholder": {
                    "Phone Number": "Telefonszám"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Utca",
                    "Tax ID": "Adóazonosító"
                },
                "placeholder": {
                    "Street": "Utca",
                    "Street in English": "Street angolul",
                    "Tax ID (RUT)": "Adóazonosító (RUT)",
                    "Number/Street/Apartment": "Szám/utca/apartman"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Kerület",
                    "Colonia": "Köln",
                    "Canton": "Kanton",
                    "Street": "Utca"
                },
                "placeholder": {
                    "District": "Kerület",
                    "Colonia": "Köln",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Szám/utca/apartman"
                }
            },
            "city": {
                "label": {
                    "City": "Város",
                    "Town/City/Locality": "Város/város/helység",
                    "Town/City": "Város/város",
                    "City/Municipality/Locality": "Város/Község/Helység",
                    "City/Town/Locality": "Város/település/helység",
                    "City/Town": "Város/Város",
                    "District": "Kerület",
                    "City/Town/Locallity": "Város/település/helység",
                    "Town/City/Sub Locality": "Város/város/község"
                },
                "placeholder": {
                    "City": "Város",
                    "Town/City": "Város/város",
                    "Town/City/Locality": "Város/város/helység",
                    "City/Municipality/Locality": "Város/Község/Helység",
                    "City/Town/Locality": "Város/település/helység",
                    "City/Town in English": "Város/város angolul",
                    "District": "Kerület",
                    "City/Town/Locallity": "Város/Város/helység",
                    "Town/City/Sub Locality": "Város/város/község"
                }
            },
            identifier: {
                label: {
                    "identifier": "Azonosító",
                    "Identifier": "Azonosító"
                },
                placeholder: {
                    "identifier": "Azonosító",
                    "Identifier": "Azonosító"
                }
            },
            "region": {
                "label": {
                    "Region": "Régió",
                    "County": "Megye",
                    "Locality": "Helység"
                },
                "placeholder": {
                    "Region": "Régió",
                    "County": "Megye",
                    "Locality": "Helység"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "PO BOX"
                },
                "placeholder": {
                    "PO BOX": "PO BOX"
                }
            },
            state: {
                "label": {
                    "State": "Állami",
                    "Province/Region": "Tartomány/régió",
                    "Province": "Tartomány",
                    "Department": "Osztály",
                    "Municipality/Comuna": "Önkormányzat/Comuna"
                },
                "placeholder": {
                    "State": "Állami",
                    "Province/Region": "Tartomány/régió",
                    "Province": "Tartomány",
                    "Department": "Osztály",
                    "Municipality/Comuna": "Önkormányzat/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaszka",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Kalifornia",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District of Columbia",
                    "Florida": "Florida",
                    "Georgia": "Grúzia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Új-Mexikó",
                    "New York": "New York",
                    "North Carolina": "Észak-Karolina",
                    "North Dakota": "Észak-Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Dél-Karolina",
                    "South Dakota": "Dél-Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "Nyugat-Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Szállítási útmutató"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "További címadatok, szállítási utasítások stb. ",
                    "Extra address information, delivery instructions, etc. in English ": "Extra címadatok, szállítási utasítások stb ",
                    "Extra address information, delivery instructions, etc": "További címadatok, szállítási utasítások stb."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Teljes név"},
                placeholder: {"Full name": "Teljes név"}
            },
            firstStreet: {
                label: {"Address 1": "Cím 1"},
                placeholder: {"Address 1": "Utca + ház/lakásszám"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Cím 2",
                    "District": "Kerület"
                },
                placeholder: {
                    "Address 2": "Utca + ház/lakásszám",
                    "District": "Kerület"
                }
            },
            city: {
                label: {City: "Város"},
                placeholder: {City: "Város"},
            },
            state: {
                label: {State: "Állami"},
                placeholder: {State: "Állami"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Irányítószám",
                    "Post Code": "Irányítószám",
                    "Postal Code": "Irányítószám",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Irányítószám",
                    "Post Code": "Irányítószám",
                    "Postal Code": "Irányítószám",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Adóazonosító"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Adóazonosító (CDI, CUIT, CUIL stb.)"
                }
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "állam/terület"},
                options: {
                    "Andaman and Nicobar Islands": "Andamán- és Nicobar-szigetek",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra és Nagar Haveli és Daman és Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu and Kashmir": "Dzsammu és Kasmír",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Puducherry",
                    "Punjab": "Pandzsáb",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "Nyugat-Bengália",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} szükséges.",
        mask: "{field} érvénytelen."
    },
    buttons: {
        submit: {
            text: "Megtakarítás",
            saving: "Megtakarítás"
        },
        cancel: {
            text: "Mégsem"
        }
    },
    disclaimer: "A cím hozzáadásához e-mail-ellenőrzés szükséges. A cím mentése után kérjük, ellenőrizze e-mailjét, és kattintson a benne lévő linkre.",
    message: {
        added: "A cím hozzáadva",
        updated: "A cím frissítve lett"
    }
}