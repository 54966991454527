export default {
    title: "Commander au nom de",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Veuillez remplir l'e-mail de l'utilisateur",
            errors: {
                myself: "L'e-mail doit être différent de votre"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Soumettre",
            processing: "Soumission"
        }
    },
    error: {
        userNotExists: "{email} n'existe pas",
        country: {
            default: "OI n'est actuellement pas disponible dans le pays de l'utilisateur",
            ALPHA3: ""
        }
    }
}