export default {
    empty: "Nic do zatwierdzenia",
    saved: "Złożone zatwierdzenia",
    review: "Proszę sprawdzić i edytować centrum kosztów, jeśli jest nieprawidłowe",
    message: {
        message: "Uwaga: Wszystkie pozycje dostępne w witrynie O-I Peripherals zostaną obciążone krzyżowo w Twoim centrum budżetu/kosztów. Jako zatwierdzający menedżer prosimy o dokładne rozważenie każdej prośby i zatwierdzenie tylko wtedy, gdy pozycja jest niezbędna dla pracownika do wypełnienia jego obowiązków służbowych."
    },
    card: {
        name: {
            label: "Nazwa"
        },
        date: {
            label: "Data"
        },
        details: {
            label: "Bliższe dane"
        },
        price: {
            label: "Cena"
        },
        manager: {
            label: "Menedżer"
        },
        requested: {
            label: "Na prośbę"
        },
        history: {
            cta: {
                load: "Wcześniejsze zgody",
                loading: "Załadunek",
                show: "Wcześniejsze zgody",
                hide: "Wcześniejsze zgody"
            },
            approvals: {
                empty: "Brak wcześniejszych zgód",
                item: {
                    status: {
                        approver: "przez {approver}",
                        system: "Biuro pomocy",
                        approved: "Zatwierdzony",
                        rejected: "Niezatwierdzone",
                        unrequired: "Zatwierdzenie nie jest wymagane",
                        reason: "Powód",
                        transferred: "Przeniesiony"
                    }
                }
            }
        },
        reason: {
            label: "Powód",
            placeholder: "Wypełnij powód"
        },
        cta: {
            approve: {
                cta: "Zatwierdzić",
                processing: "Oszczędność"
            },
            disapprove: {
                cta: "Nie zatwierdzam",
                processing: "Oszczędność"
            }
        },
        saved: "Złożone zatwierdzenia"
    }
}