<template>
    <approvals-list-card-section :label="t('label')" :css-class="cssClass">
        <form-textarea :model-value="modelValue"
                       :textarea-class="'form-control form-control-sm w-100'"
                       :validator="validator"
                       :placeholder="t('placeholder')"
                       :disabled="disabled"
                       @input="$emit('update:modelValue', $event.target.value)"/>
    </approvals-list-card-section>
</template>

<script>

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";
    import FormTextarea from "@/components/form/FormTextarea";

    export default {
        name: "ApprovalsListCardReason",
        emits: ["update:modelValue"],
        components: {
            ApprovalsListCardSection,
            FormTextarea
        },
        props: {
            modelValue: {},
            validator: {
                type: Object,
                required: true
            },
            approval: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                default: () => "col-12"
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "components.approvals.list.card.reason"
            };
        },
    }
</script>