export default {
    title: "Transfer product",
    model: {
        email: {
            label: "Email",
            placeholder: "Fill email",
            errors: {
                myself: "Email must differ from your"
            }
        },
        reason: {
            label: "Reason",
            placeholder: "Reason"
        }
    },
    message: "The recipient will be sent an email informing them of the transfer.",
    saved: "Saved",
    cta: {
        submit: {
            text: "Submit",
            processing: "Submitting"
        },
        cancel: {
            text: "Cancel"
        }
    }
}