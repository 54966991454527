export default {
    title: "Transférer le produit",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Remplir l'email",
            errors: {
                myself: "L'e-mail doit être différent de votre"
            }
        },
        reason: {
            label: "Raison",
            placeholder: "Raison"
        }
    },
    message: "Le destinataire recevra un email l'informant du transfert.",
    saved: "Sauvé",
    cta: {
        submit: {
            text: "Soumettre",
            processing: "Soumission"
        },
        cancel: {
            text: "Annuler"
        }
    }
}