import {getPickerConfig} from "@/store/picker/config";

const generateDateConfig = locale => ({
    enableTime: false,
    noCalendar: false,
    disableMobile: true,
    dateFormat: "U",
    altInput: true,
    altFormat: getPickerConfig(locale).displayDateFormat,
    locale: getPickerConfig(locale).locale
})

export default {
    namespaced: true,
    state: {
        locale: null
    },
    getters: {
        dateConfig: state => generateDateConfig(state.locale)
    },
    mutations: {
        setLocale: (state, locale) => state.locale = locale
    },
    actions: {
        setLocale: (context, locale) => context.commit("setLocale", locale)
    }
}