export default {
    title: "Termék átvitele",
    model: {
        email: {
            label: "Email",
            placeholder: "Töltse ki az e-mailt",
            errors: {
                myself: "Az e-mail-címnek különböznie kell az Önétől"
            }
        },
        reason: {
            label: "Ok",
            placeholder: "Ok"
        }
    },
    message: "A címzett e-mailt küld az átutalásról.",
    saved: "Mentve",
    cta: {
        submit: {
            text: "Beküld",
            processing: "Beküldés"
        },
        cancel: {
            text: "Mégsem"
        }
    }
}