export default {
    title: {
        new: "Aggiungi nuovo indirizzo",
        complete: "Indirizzo completo",
        edit: "Modifica indirizzo"
    },
    incomplete: "Si prega di compilare i campi obbligatori dell'indirizzo",
    info: {
        "CHN": "Inserisci il tuo indirizzo in cinese.</br> Inserisci il tuo indirizzo in cinese.",
        "BGR": "Inserisci il tuo <b>indirizzo di casa</b> in inglese per ricevere i tuoi articoli.",
        "PRI": "Per ricevere i pacchi, inserisci l'indirizzo di casa e non quello di una casella postale.",
        "SGP": "Si prega di includere il numero della propria unità nel proprio indirizzo.",
        "SVK": "Inserisci il tuo indirizzo in <b>inglese</b>",
        "POL": "Inserisci il tuo indirizzo in <b>inglese</b>"
    },
    model: {
        country: {
            label: "Paese"
        }
    },
    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nome"
                },
                "placeholder": {
                    "Name": "Nome",
                    "Name in English": "Nome in inglese"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "E-mail personale"
                },
                placeholder: {
                    "Personal Email": "E-mail personale"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Email del responsabile"
                },
                placeholder: {
                    "Manager Email": "Email del responsabile"
                }
            },
            managerName: {
                label: {"Manager Name": "Nome del gestore"},
                placeholder: {"Manager Name": "Nome del gestore"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "Dottore Commercialista"
                },
                "placeholder": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "Dottore Commercialista"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincia",
                    "Prefecture": "Prefettura",
                    "Province/Region": "Provincia/Regione",
                    "Province Code": "Codice provinciale"
                },
                "placeholder": {
                    "Province": "Provincia",
                    "Prefecture": "Prefettura",
                    "Province/Region": "Provincia/Regione",
                    "Province Code": "Codice provinciale"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Numero di telefono"
                },
                "placeholder": {
                    "Phone Number": "Numero di telefono"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Strada",
                    "Tax ID": "Codice fiscale"
                },
                "placeholder": {
                    "Street": "Strada",
                    "Street in English": "Strada in inglese",
                    "Tax ID (RUT)": "Codice fiscale (RUT)",
                    "Number/Street/Apartment": "Numero/Via/Appartamento"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Quartiere",
                    "Colonia": "Colonia",
                    "Canton": "Cantone",
                    "Street": "Strada"
                },
                "placeholder": {
                    "District": "Quartiere",
                    "Colonia": "Colonia",
                    "Canton": "Cantone",
                    "Number/Street/Apartment": "Numero/Via/Appartamento"
                }
            },
            "city": {
                "label": {
                    "City": "Città",
                    "Town/City/Locality": "Città/Località/Paese",
                    "Town/City": "Città/Paese",
                    "City/Municipality/Locality": "Città/Comune/Località",
                    "City/Town/Locality": "Città/Paese/Località",
                    "City/Town": "Città/Paese",
                    "District": "Quartiere",
                    "City/Town/Locallity": "Città/Paese/Località",
                    "Town/City/Sub Locality": "Città/Località/Sottolocalità"
                },
                "placeholder": {
                    "City": "Città",
                    "Town/City": "Città/Paese",
                    "Town/City/Locality": "Città/Località/Paese",
                    "City/Municipality/Locality": "Città/Comune/Località",
                    "City/Town/Locality": "Città/Paese/Località",
                    "City/Town in English": "Città/Paese in inglese",
                    "District": "Quartiere",
                    "City/Town/Locallity": "Città/Paese/Località",
                    "Town/City/Sub Locality": "Città/Località/Sottolocalità"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificatore",
                    "Identifier": "Identificatore"
                },
                placeholder: {
                    "identifier": "Identificatore",
                    "Identifier": "Identificatore"
                }
            },
            "region": {
                "label": {
                    "Region": "Regione",
                    "County": "Contea",
                    "Locality": "Località"
                },
                "placeholder": {
                    "Region": "Regione",
                    "County": "Contea",
                    "Locality": "Località"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "CASELLA POSTALE"
                },
                "placeholder": {
                    "PO BOX": "CASELLA POSTALE"
                }
            },
            state: {
                "label": {
                    "State": "Stato",
                    "Province/Region": "Provincia/Regione",
                    "Province": "Provincia",
                    "Department": "Dipartimento",
                    "Municipality/Comuna": "Comune/Comuna"
                },
                "placeholder": {
                    "State": "Stato",
                    "Province/Region": "Provincia/Regione",
                    "Province": "Provincia",
                    "Department": "Dipartimento",
                    "Municipality/Comuna": "Comune/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distretto di Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Nuovo Messico",
                    "New York": "New York",
                    "North Carolina": "Carolina del Nord",
                    "North Dakota": "Dakota del Nord",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Carolina del Sud",
                    "South Dakota": "Dakota del Sud",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "Virginia Occidentale",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Istruzioni per la consegna"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Ulteriori informazioni sull'indirizzo, istruzioni per la consegna, ecc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Informazioni aggiuntive sull'indirizzo, istruzioni per la consegna, ecc. in inglese ",
                    "Extra address information, delivery instructions, etc": "Ulteriori informazioni sull'indirizzo, istruzioni per la consegna, ecc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Nome e cognome"},
                placeholder: {"Full name": "Nome e cognome"}
            },
            firstStreet: {
                label: {"Address 1": "Indirizzo 1"},
                placeholder: {"Address 1": "Via + numero civico/appartamento"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Indirizzo 2",
                    "District": "Quartiere"
                },
                placeholder: {
                    "Address 2": "Via + numero civico/appartamento",
                    "District": "Quartiere"
                }
            },
            city: {
                label: {City: "Città"},
                placeholder: {City: "Città"},
            },
            state: {
                label: {State: "Stato"},
                placeholder: {State: "Stato"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "Dottore Commercialista"
                },
                "placeholder": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "Dottore Commercialista"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Codice fiscale"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Codice Fiscale (CDI, CUIT, CUIL, ecc.)"
                }
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "Stato/Territorio"},
                options: {
                    "Andaman and Nicobar Islands": "Isole Andamane e Nicobare",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "L'Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Il Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra e Nagar Haveli e Daman e Diu",
                    "Delhi": "Nuova Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "L'Himachal Pradesh",
                    "Jammu and Kashmir": "Jammu e Kashmir",
                    "Jharkhand": "Il Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Laccadive",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Il Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Il Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Pondicherry",
                    "Punjab": "Il Punjab",
                    "Rajasthan": "Il Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "L'Uttarakhand",
                    "West Bengal": "Bengala Occidentale",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} è obbligatorio.",
        mask: "{field} non è valido."
    },
    buttons: {
        submit: {
            text: "Salva",
            saving: "Risparmio"
        },
        cancel: {
            text: "Cancellare"
        }
    },
    disclaimer: "L'aggiunta di questo indirizzo richiederà la verifica dell'email. Dopo aver salvato l'indirizzo, controlla la tua email e clicca sul link al suo interno.",
    message: {
        added: "L'indirizzo è stato aggiunto",
        updated: "L'indirizzo è stato aggiornato"
    }
}