<template>
    <approvals-list-card-section v-for="field in fields"
                                 :key="field.id"
                                 :label="field.label"
                                 :text="field.value"
                                 :css-class="cssClass"/>
</template>

<script>

    import {v4 as uuidv4} from "uuid";

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardFields",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            },
            cssClass: String
        },
        computed: {
            fields() {

                return this.staticFields
                    .filter(staticField => true === !!this.approval.dynamic[staticField.payload.field])
                    .filter(
                        staticField => this.editableFields.reduce(
                            (display, editableField) => {

                                if (true === display && "undefined" !== typeof editableField.decorators) {

                                    // Check for editable field does not use this field as decorator
                                    for (let i = 0; i < editableField.decorators.length; i++) {

                                        if ("undefined" !== typeof editableField.decorators[i].values) {

                                            for (let k = 0; k < editableField.decorators[i].values.length; k++) {

                                                // Check for decorator source is current static field
                                                if (
                                                    editableField.decorators[i].values[k].source
                                                    && "order" === editableField.decorators[i].values[k].source.type
                                                    && staticField.payload.field === editableField.decorators[i].values[k].source.value
                                                ) {

                                                    // Hide this static field because it's displayed by decorator
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                }

                                return display;
                            },
                            true
                        )
                    )
                    .map(
                        staticField => ({
                            id: uuidv4(),
                            label: staticField.label,
                            value: this.approval.dynamic[staticField.payload.field]
                        })
                    );
            }
        }
    }
</script>