export default {
    empty: "Nichts zu genehmigen",
    saved: "Eingereichte Genehmigungen",
    review: "Bitte prüfen Sie die Kostenstelle und bearbeiten Sie sie, falls sie falsch ist.",
    message: {
        message: "Bitte beachten Sie: Alle auf der Seite O-I Peripheriegeräte verfügbaren Artikel werden Ihrem Budget/Ihrer Kostenstelle in Rechnung gestellt. Als genehmigender Manager bitten wir Sie, jede Anfrage sorgfältig zu prüfen und nur dann zu genehmigen, wenn der Artikel für die Erfüllung der Arbeitspflichten Ihres Mitarbeiters erforderlich ist."
    },
    card: {
        name: {
            label: "Name"
        },
        date: {
            label: "Datum"
        },
        details: {
            label: "Details"
        },
        price: {
            label: "Preis"
        },
        manager: {
            label: "Manager"
        },
        requested: {
            label: "Angefordert von"
        },
        history: {
            cta: {
                load: "Vorherige Genehmigungen",
                loading: "Laden",
                show: "Vorherige Genehmigungen",
                hide: "Vorherige Genehmigungen"
            },
            approvals: {
                empty: "Keine vorherigen Genehmigungen",
                item: {
                    status: {
                        approver: "von {approver}",
                        system: "Hilfe-Center",
                        approved: "Genehmigt",
                        rejected: "Nicht genehmigt",
                        unrequired: "Genehmigung nicht erforderlich",
                        reason: "Grund",
                        transferred: "Übertragen"
                    }
                }
            }
        },
        reason: {
            label: "Grund",
            placeholder: "Grund ausfüllen"
        },
        cta: {
            approve: {
                cta: "Genehmigen",
                processing: "Speichern"
            },
            disapprove: {
                cta: "Nicht genehmigen",
                processing: "Speichern"
            }
        },
        saved: "Eingereichte Genehmigungen"
    }
}