<template>
    <div :class="cssClass">
        <approvals-list-card-history-cta :approval="approval" :config="config"
                                         :display="display"
                                         @display="display = $event"
                                         @loaded="approvals = $event"/>

        <approvals-list-card-history-approvals v-if="null !== approvals && true === display"
                                               :approvals="approvals"
                                               :config="config"/>
    </div>
</template>

<script>


    import ApprovalsListCardHistoryCta from "@/components/approvals/list/card/history/ApprovalsListCardHistoryCta";
    import ApprovalsListCardHistoryApprovals from "@/components/approvals/list/card/history/ApprovalsListCardHistoryApprovals";

    export default {
        name: "ApprovalsListCardHistory",
        components: {
            ApprovalsListCardHistoryApprovals,
            ApprovalsListCardHistoryCta
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            config: {
                type: Array,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            },
            cssClass: {
                type: String,
                default: "col-12"
            },
        },
        data() {

            return {
                t_path: "components.approvals.list.card.history",
                display: false,
                approvals: null
            };
        }
    }
</script>