<template>
    <approvals-list-card-section v-if="null !== requested" :label="t('label')" :text="requested" :css-class="cssClass"/>
</template>

<script>

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardRequested",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                default: () => "col-12"
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.card.requested"
            };
        },
        computed: {
            requested() {

                if (
                    null !== this.approval.order.requested_by
                    && this.approval.user.id !== this.approval.order.requested_by.id
                ) {

                    return this.approval.order.requested_by.name;
                }

                return null;
            }
        }
    }
</script>