<template>
    <div>
        <div class="small text-black-50 pb-1">
            <div v-for="item in before" class="pb-2">
                <div class="font-weight-bold">{{item.label}}</div>

                <div>{{item.message}}</div>
            </div>
        </div>

        <slot></slot>

        <div class="small text-black-50 pb-1">
            <div v-for="item in after" class="pt-2">
                <div class="font-weight-bold">{{item.label}}</div>

                <div>{{item.message}}</div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ApprovalsListCardEditableDecorator",
        props: {
            approval: {
                type: Object,
                required: true
            },
            field: {
                type: Object,
                required: true
            },
            filterDecoratorDependency: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            decorators() {

                if ("undefined" === typeof this.field.decorators) {

                    return [];
                }

                return this.field.decorators.filter(
                    decorator => {

                        if (
                            true === this.filterDecoratorDependency
                            && "undefined" !== typeof decorator.values
                            && 0 < decorator.values.length
                        ) {

                            for (let i = 0; i < decorator.values.length; i++) {

                                if (
                                    decorator.values[i].source
                                    && "order" === decorator.values[i].source.type
                                    && "undefined" === typeof this.approval.dynamic[decorator.values[i].source.value]
                                ) {

                                    return false;
                                }

                            }
                        }

                        return true;
                    }
                )
            },
            items() {

                return this.decorators.map(item => {

                    const replacements = ("undefined" === typeof item.values || !item.values.length ? [] : item.values)
                        .reduce((result, itemValue) => {

                            if (itemValue.source && "order" === itemValue.source.type) {

                                result[itemValue.replacement] = this.approval.dynamic[itemValue.source.value]
                                    ? this.approval.dynamic[itemValue.source.value]
                                    : " - ";
                            }

                            return result;
                        }, {});

                    const translationKey = `approval_config.${this.field.payload.field}.decorators.${item.name}`;

                    return {
                        position: item.position,
                        label: this.$te(`${translationKey}.label`)
                            ? this.$t(`${translationKey}.label`, replacements)
                            : this.$t(item.label, replacements),
                        message: this.$te(`${translationKey}.message`)
                            ? this.$t(`${translationKey}.message`, replacements)
                            : this.$t(item.message, replacements),
                    }
                });
            },
            before() {

                return this.items.filter(item => "before" === item.position);
            },
            after() {

                return this.items.filter(item => "after" === item.position);
            }
        }
    }
</script>