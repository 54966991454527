export default {
    title: "Transferir produto",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Preencher e-mail",
            errors: {
                myself: "O e-mail deve ser diferente do seu"
            }
        },
        reason: {
            label: "Razão",
            placeholder: "Razão"
        }
    },
    message: "O destinatário receberá um e-mail informando sobre a transferência.",
    saved: "Salvo",
    cta: {
        submit: {
            text: "Enviar",
            processing: "Enviando"
        },
        cancel: {
            text: "Cancelar"
        }
    }
}