export default {
    empty: "Nada que aprobar",
    saved: "Aprobaciones presentadas",
    review: "Revise y edite el centro de costos si es incorrecto",
    message: {
        message: "Tenga en cuenta: todos los artículos disponibles en el sitio de periféricos O-I se cargarán a su presupuesto/centro de costos. Como gerente de aprobación, le pedimos que considere cuidadosamente cada solicitud y la apruebe solo cuando el artículo sea necesario para que su empleado complete sus responsabilidades laborales."
    },
    card: {
        name: {
            label: "Nombre"
        },
        date: {
            label: "Fecha"
        },
        details: {
            label: "Detalles"
        },
        price: {
            label: "Precio"
        },
        manager: {
            label: "Gerente"
        },
        requested: {
            label: "Solicitado por"
        },
        history: {
            cta: {
                load: "Aprobaciones previas",
                loading: "Cargando",
                show: "Aprobaciones previas",
                hide: "Aprobaciones previas"
            },
            approvals: {
                empty: "Sin aprobaciones previas",
                item: {
                    status: {
                        approver: "por {approver}",
                        system: "Mesa de ayuda",
                        approved: "Aprobado",
                        rejected: "No aprobado",
                        unrequired: "Aprobación no requerida",
                        reason: "Razón",
                        transferred: "Transferido"
                    }
                }
            }
        },
        reason: {
            label: "Razón",
            placeholder: "Rellene el motivo"
        },
        cta: {
            approve: {
                cta: "Aprobar",
                processing: "Ahorro"
            },
            disapprove: {
                cta: "No lo apruebo",
                processing: "Ahorro"
            }
        },
        saved: "Aprobaciones presentadas"
    }
}