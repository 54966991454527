<template>
    <approvals-list-card-section :label="t('label')" :text="approval.user.name" :css-class="cssClass"/>
</template>

<script>

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardName",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: String
        },
        data() {

            return {
                t_path: "components.approvals.list.card.name"
            };
        }
    }
</script>