<template>
    <div>
        <div class="d-md-none pb-3 pb-md-0">
            <router-link class="btn btn-primary btn-select-accessories" :to="{name:'catalog'}">
                {{t('button.accessories')}}
            </router-link>
        </div>

        <div class="d-none d-md-block">
            <loading v-if="loading"></loading>
            <div v-else>
                <div class="home-catalog-message" v-if="catalogMessage" v-html="catalogMessage"></div>

                <div v-if="error">{{t('error')}}</div>
                <div v-else>
                    <products-grouping v-for="grouping in products" :grouping="grouping"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixin_constraints from "@/mixins/constraints";
    import {retryRequest} from "@/helpers/request";
    import emitter, {EVENTS} from "@/api/events";

    import Loading from "@/components/Loading";
    import ProductsGrouping from "@/components/products/ProductsGrouping";

    export default {
        name: "ViewsHomeTypeProducts",
        mixins: [mixin_constraints],
        components: {
            ProductsGrouping,
            Loading
        },
        props: {
            grouping: {
                type: String,
                required: true
            },
            categories: {
                type: Array,
                required: true
            },
            catalogMessage: String
        },
        data() {

            return {
                t_path: "views.home",
                loading: false,
                error: false,
                products: [],
            }
        },
        computed: {
            ...mapGetters({
                maxItems: "config/page/home/maxItems"
            })
        },
        methods: {
            processProductsGrouping(productsGrouping, options = {}) {

                const items = this.constraints_filterAvailableProducts(productsGrouping.products);
                const total = "undefined" !== typeof productsGrouping.total
                    ? productsGrouping.total
                    : items.length;

                if ("undefined" !== typeof options.updateIndex) {

                    if (0 < items.length) {

                        this.products[options.updateIndex].items = items.slice(0, this.maxItems);
                        this.products[options.updateIndex].showMore = total > items.slice(0, this.maxItems).length;
                    } else {

                        this.products.splice(options.updateIndex, 1);
                    }
                } else {

                    this.$activity.log("catalog_grouping_products", {
                        grouping_id: productsGrouping._id,
                        products: items.slice(0, this.maxItems),
                        constraints: this.$store.getters["user/constraints/type"]
                    });

                    // Check for category has available items
                    if (0 < items.length) {

                        this.products.push({
                            ...productsGrouping,
                            id: productsGrouping._id,
                            type: this.grouping,
                            items: items.slice(0, this.maxItems),
                            showMore: total > items.slice(0, this.maxItems).length
                        });
                    }
                }
            },
            fetch() {

                this.loading = true;

                const promises = this.categories.map(item => retryRequest(() => this.$platform.get(`/api/v2/grouping/${this.grouping}/${item.id}`)));

                return Promise
                    .all(promises)
                    .then(responses => responses.forEach(response => this.processProductsGrouping(response.data.results)))
                    .catch(() => this.error = true)
                    .finally(() => this.loading = false);
            },
            refresh() {

                this.products.forEach((productsGroup, productsGroupIndex) => this.$platform
                    .get(`/api/v2/grouping/${this.grouping}/${productsGroup.id}`)
                    .then(response => this.processProductsGrouping(response.data.results, {updateIndex: productsGroupIndex}))
                );
            }
        },
        mounted() {

            this.fetch();

            emitter.on(EVENTS.USER_CONSTRAINTS_FETCH, this.refresh);
        },
        beforeUnmount() {

            emitter.off(EVENTS.USER_CONSTRAINTS_FETCH, this.refresh);
        }
    }
</script>