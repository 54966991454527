export default {
    methods: {
        constraints_updateConstraintsWithCart(constraints) {

            const cart = this.$store.getters["user/cart/items"];
            const resultConstraints = JSON.parse(JSON.stringify(constraints));

            Object.keys(resultConstraints).forEach(type => {

                resultConstraints[type] -= cart.reduce((total, cartItem) => {

                    if (-1 !== cartItem.product_type.indexOf(type)) {

                        total += cartItem.quantity;
                    }

                    return total;
                }, 0);

            });

            return resultConstraints;
        },
        constraints_getProductTypeAvailableAmount(product) {

            const constraints = this.$store.getters["user/constraints/type"];

            return Math.min.apply(Math, product.product_type.map(type => constraints[type] ?? 0));
        },
        constraints_getProductAvailableAmount(product) {

            const constraints = this.$store.getters["user/constraints/type"];
            const cart = this.$store.getters["user/cart/items"];

            const resultConstraints = JSON.parse(JSON.stringify(constraints));

            // Handle cart items
            Object.keys(resultConstraints).forEach(type => {

                resultConstraints[type] -= cart.reduce((total, cartItem) => {

                    if (cartItem.sku != product.sku) {

                        if (-1 !== cartItem.product_type.indexOf(type)) {

                            total += cartItem.quantity;
                        }
                    }


                    return total;
                }, 0);
            });


            return product.product_type.reduce((result, type) => {

                if (0 == result || result > resultConstraints[type]) {

                    result = resultConstraints[type];
                }

                return result;
            }, 0);
        },
        constraints_hasUserAvailableProducts(handleCart) {

            const constraints = handleCart
                ? this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"])
                : this.$store.getters["user/constraints/type"];

            return null !== constraints && 0 <= Object.keys(constraints).reduce((result, type) => {

                return result + constraints[type];
            }, 0);

        },
        constraints_filterAvailableProducts(products, handleCart) {

            const constraints = handleCart
                ? this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"])
                : this.$store.getters["user/constraints/type"];

            return products
                .filter(item => {

                if ("undefined" !== typeof item.allowed_to_order && false === item.allowed_to_order) {

                    return true;
                }

                for (let i = 0; i < item.product_type.length; i++) {

                    if ("undefined" !== typeof constraints[item.product_type[i]] && 0 >= constraints[item.product_type[i]]) {

                        return false;
                    }
                }

                return true;
            })
                .sort((a, b) => {

                    if (false === a.allowed_to_order && a.allowed_to_order !== b.allowed_to_order) {

                        return 1;
                    }

                    if (false === b.allowed_to_order && b.allowed_to_order !== a.allowed_to_order) {

                        return -1;
                    }

                    return 0;
                });
        },
        constraints_hasAnyAvailableProducts() {

            const constraints = this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"]);

            return 0 < Object.keys(constraints).reduce((result, type) => result + constraints[type], 0);
        }
    }
}