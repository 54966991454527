<template>
    <div class="pb-5 col-12 col-lg-6">
        <panel>
            <div class="row">
                <approvals-list-card-name :approval="approval"/>

                <approvals-list-card-date :approval="approval"/>

                <approvals-list-card-details :approval="approval"/>

                <approvals-list-card-history :approval="approval"
                                             :config="config"
                                             :editable-fields="editableFields"
                                             :static-fields="staticFields"/>

                <approvals-list-card-price :approval="approval"/>

                <approvals-list-card-requested :approval="approval"/>

                <approvals-list-card-manager :approval="approval"/>

                <approvals-list-card-fields :approval="approval"
                                            :editable-fields="editableFields"
                                            :static-fields="staticFields"/>

                <approvals-list-card-editable v-for="field in editableFields"
                                              :model-value="model"
                                              :validator="v$.model[field.payload.field]"
                                              :approval="approval"
                                              :field="field"
                                              :disabled="processing"/>

                <approvals-list-card-reason v-model="model.reason"
                                            :approval="approval"
                                            :validator="v$.model.reason"
                                            :disabled="processing"/>
            </div>

            <div class="row">
                <div class="col-6">
                    <button type="submit" class="btn btn-sm btn-primary" :disabled="processing" @click="approve">
                        <span v-if="true === model.__approve && true === processing">
                            <spinner v-if="processing"/>
                            {{t('cta.approve.processing')}}
                        </span>
                        <span v-else>
                            {{t('cta.approve.cta')}}
                        </span>
                    </button>
                </div>
                <div class="col-6 text-right">
                    <button type="button"
                            class="btn btn-sm btn-outline-primary"
                            :disabled="processing"
                            @click="disapprove">
                        <span v-if="false === model.__approve && true === processing">
                            <spinner v-if="processing"/>
                            {{t('cta.disapprove.processing')}}
                        </span>
                        <span v-else>
                            {{t('cta.disapprove.cta')}}
                        </span>
                    </button>
                </div>
            </div>
        </panel>
    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {requiredIf} from "@vuelidate/validators";

    import mixin_validator from "@/helpers/validator";
    import mixin_dynamic_field from "@/mixins/dynamic_field";

    import ApprovalsListCardDate from "@/components/approvals/list/card/ApprovalsListCardDate";
    import ApprovalsListCardDetails from "@/components/approvals/list/card/ApprovalsListCardDetails";
    import ApprovalsListCardEditable from "@/components/approvals/list/card/ApprovalsListCardEditable";
    import ApprovalsListCardFields from "@/components/approvals/list/card/ApprovalsListCardFields";
    import ApprovalsListCardHistory from "@/components/approvals/list/card/ApprovalsListCardHistory";
    import ApprovalsListCardManager from "@/components/approvals/list/card/ApprovalsListCardManager";
    import ApprovalsListCardName from "@/components/approvals/list/card/ApprovalsListCardName";
    import ApprovalsListCardPrice from "@/components/approvals/list/card/ApprovalsListCardPrice";
    import ApprovalsListCardReason from "@/components/approvals/list/card/ApprovalsListCardReason";
    import ApprovalsListCardRequested from "@/components/approvals/list/card/ApprovalsListCardRequested";
    import Panel from "@/components/Panel";
    import Spinner from "@/components/Spinner";

    export default {
        name: "ApprovalsListCard",
        mixins: [mixin_validator, mixin_dynamic_field],
        emits: ["refresh"],
        components: {
            ApprovalsListCardDate,
            ApprovalsListCardDetails,
            ApprovalsListCardEditable,
            ApprovalsListCardFields,
            ApprovalsListCardHistory,
            ApprovalsListCardManager,
            ApprovalsListCardName,
            ApprovalsListCardPrice,
            ApprovalsListCardReason,
            ApprovalsListCardRequested,
            Panel,
            Spinner
        },
        props: {
            config: {
                type: Array,
                required: true
            },
            approval: {
                type: Object,
                required: true
            },
            editableFields: {
                type: Array,
                required: true
            },
            staticFields: {
                type: Array,
                required: true
            }
        },
        setup() {

            return {
                v$: useVuelidate()
            };
        },
        data() {

            return {
                t_path: "components.approvals.list.card",
                processing: false,
                model: {
                    __approve: true,
                    reason: null,
                    // Append dynamic fields
                    ...this.config.reduce(
                        (result, field) => {

                            if (true === field.editable || "undefined" !== typeof field.watch) {

                                result[field.payload.field] = this.approval.model[field.payload.field];
                            }

                            return result;
                        },
                        {}
                    )
                }
            };
        },
        validations() {

            return {
                model: {
                    reason: {
                        required: requiredIf(() => false === this.model.__approve)
                    },
                    ...this.editableFields.reduce(
                        (approvalConfigResult, config) => {

                            approvalConfigResult[config.payload.field] = this.validator_createDynamicValidationRules(
                                config.validation,
                                {}
                            );

                            approvalConfigResult[config.payload.field] = this.validator_applyDynamicMask(
                                config.mask,
                                approvalConfigResult[config.payload.field]
                            );

                            return approvalConfigResult;
                        },
                        {}
                    )
                }
            };
        },
        methods: {
            submit() {

                this.$order
                    .post(`/users/${this.$store.getters["user/id"]}/approvals`, {
                        [this.approval.order.id]: {
                            approved: this.model.__approve,
                            reason: this.model.reason,
                            // Collect dynamic fields
                            ...this.editableFields.reduce(
                                (result, field) => ({
                                    ...result,
                                    [field.payload.field]: this.model[field.payload.field]
                                }),
                                {}
                            )
                        }
                    })
                    .then(() => this.$store.dispatch("user/surveys/fetch"))
                    .then(() => {

                        this.$emit("refresh");

                        this.$modal
                            .message(this.t("saved"))
                            .then(() => true, () => true);
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false)
            },
            approve() {

                this.model.__approve = true;
                this.base_submit("model", "submit", "processing");
            },
            disapprove() {

                this.model.__approve = false;
                this.base_submit("model", "submit", "processing");
            }
        },
        mounted() {

            this.config.forEach(
                field => this.dynamic_field_watch(field, "model")
            );
        }
    }
</script>
