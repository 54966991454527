export default {
    props: {
        validator: {
            type: Object
        },
        placeholder: {
            type: String
        },
        autocomplete: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        focus: {
            type: Boolean
        },
        displayErrors: {
            type: Boolean,
            default: true
        },
        tabindex: {
            type: Number
        },
        errors: {
            type: [Object, String]
        }
    },
    computed: {
        labelFor() {

            return "field_" + Math.random().toString().replace("0.", "")
        },
        isInvalid() {

            if (!this.validator) {

                return false;
            }

            if ("undefined" !== typeof this.displayErrors) {

                return this.displayErrors && this.validator.$invalid && this.validator.$dirty;
            }

            return this.validator.$invalid && this.validator.$dirty;
        },
        isValid() {

            if (!this.validator) {

                return false;
            }

            if ('undefined' !== typeof this.displayErrors) {

                return this.displayErrors && this.validator.$dirty && !this.validator.$invalid;
            }

            return this.validator.$dirty && !this.validator.$invalid;
        },
        _required() {

            if (!this.validator) {

                return false;
            }

            return 'undefined' !== typeof this.validator.required;
        },
        _errors() {

            let path, translation;

            const errors = this.errors ? this.errors : {};

            if (!this.validator) {

                 return [];
            }

            return this.validator
                .$errors
                .map(error => {

                    if ("string" === typeof this.errors) {

                        path = `${errors}.${error.$validator}`;

                        translation = this.$t(path);

                        if (path != translation) {

                            return translation;
                        }

                    } else if ("object" === typeof this.errors && "undefined" !== typeof this.errors[error.$validator]) {

                        return this.errors[error.$validator];
                    } else {

                        path = `errors.${error.$validator}`;

                        translation = this.$t(path);

                        if (path != translation) {

                            return translation;
                        }
                    }

                    return error.$message;
                })
                .slice(0, 1);
        }
    },
    mounted() {

        if (this.focus && this.$refs && this.$refs.element) {

            this.$refs.element.focus();
        }
    }
}