export default {
    empty: "Nincs mit helyeselni",
    saved: "A jóváhagyások benyújtva",
    review: "Kérjük, tekintse át és szerkessze a költséghelyet, ha hibás",
    message: {
        message: "Kérjük, vegye figyelembe: Az összes elem elérhető a O-I-ben A perifériák webhelye az Ön költségvetését/költséghelyét terheli. Jóváhagyó vezetőként kérjük, hogy alaposan fontolja meg az egyes kéréseket, és csak akkor hagyja jóvá, ha a tétel szükséges ahhoz, hogy munkatársa munkaköri feladatait teljesítse."
    },
    card: {
        name: {
            label: "Név"
        },
        date: {
            label: "Dátum"
        },
        details: {
            label: "Részletek"
        },
        price: {
            label: "Ár"
        },
        manager: {
            label: "Menedzser"
        },
        requested: {
            label: "kérte"
        },
        history: {
            cta: {
                load: "Előzetes jóváhagyások",
                loading: "Terhelés",
                show: "Előzetes jóváhagyások",
                hide: "Előzetes jóváhagyások"
            },
            approvals: {
                empty: "Nincs előzetes jóváhagyás",
                item: {
                    status: {
                        approver: "szerző: {approver}",
                        system: "Help Desk",
                        approved: "Jóváhagyott",
                        rejected: "Nincs jóváhagyva",
                        unrequired: "Jóváhagyás nem szükséges",
                        reason: "Ok",
                        transferred: "Áthelyezve"
                    }
                }
            }
        },
        reason: {
            label: "Ok",
            placeholder: "Töltse ki az okot"
        },
        cta: {
            approve: {
                cta: "Jóváhagyni",
                processing: "Megtakarítás"
            },
            disapprove: {
                cta: "Nem hagyja jóvá",
                processing: "Megtakarítás"
            }
        },
        saved: "A jóváhagyások benyújtva"
    }
}