export default {
    required: "Je vyžadována hodnota",
    email: "Hodnota není platná e-mailová adresa",
    mask: "Hodnota je neplatná",
    maxValue: "Maximální hodnota je {maxValue}",
    regex: "Hodnota je neplatná",
    server: {
        DELEGATES_NOT_ENABLED: "Delegáti nejsou pro tohoto partnera povoleni.",
        DELEGATE_DOES_NOT_EXIST: "Delegát neexistuje",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Nelze aktualizovat delegáta, který vám nepatří.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Nelze smazat delegáta, který vám nepatří.",
        DELEGATE_ALREADY_EXISTS: "Existující pravidlo delegování odpovídající těmto kritériím již existuje.",
        DELEGATE_CANNOT_BE_SELF: "Nemůžete se přidat jako delegát.",
        IMPERSONATE_USER_NO_PERMISSIONS: "Uživatel {email} nemá přístup k DXC Gear a jejich jménem nelze provádět žádné objednávky",
        INVALID_USER_TYPE: "Typ uživatele není zaměstnanec",
        INVALID_EMPLOYMENT_STATUS: "Uživatel není aktivním zaměstnancem",
        USER_NOT_FOUND: "Uživatel nenalezen",
        "Invalid user type": "Neplatný typ uživatele",
        "User not found": "Uživatel nenalezen"
    }
}