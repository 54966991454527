<template>
    <approvals-list-card-section :label="field.label" :class="cssClass">
        <approvals-list-card-editable-decorator :approval="approval" :field="field">
            <form-autocomplete v-if="true === isTextbox"
                               v-model="modelValue[field.payload.field]"
                               class="mb-0"
                               textbox-class="form-control form-control-sm"
                               :validator="validator"
                               :placeholder="field.placeholder"
                               :mask="field.mask"
                               :errors="field.errors"
                               :disabled="disabled || field.disabled"
                               :message-not-found="dynamic_field_messageNotFound(field, 'approval_config.' + field.payload.field)"
                               :infinity-scroll="dynamic_field_infinityScroll(field)"
                               :strict="isStrict"
                               @search="onAutocompleteSearch"/>
        </approvals-list-card-editable-decorator>
    </approvals-list-card-section>
</template>

<script>

    import mixin_dynamic_field from "@/mixins/dynamic_field";

    import ApprovalsListCardEditableDecorator from "@/components/approvals/list/card/editable/ApprovalsListCardEditableDecorator";
    import FormAutocomplete from "@/components/form/FormAutocomplete";
    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection.vue";

    export default {
        name: "ApprovalsListCardEditable",
        mixins: [mixin_dynamic_field],
        emits: ["update:modelValue"],
        components: {
            ApprovalsListCardSection,
            ApprovalsListCardEditableDecorator,
            FormAutocomplete
        },
        props: {
            modelValue: {},
            approval: {
                type: Object,
                required: true
            },
            field: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                default: "col-12"
            },
            validator: Object,
            disabled: Boolean
        },
        computed: {
            isTextbox() {

                return "textbox" === this.field.type;
            },
            isStrict() {

                return this.field.typeahead && this.field.typeahead.strict;
            }
        },
        methods: {
            onAutocompleteSearch(value, render) {

                this.dynamic_field_typeahead(this.field, value, render, {user_id: this.approval.user.id});
            }
        }
    }
</script>