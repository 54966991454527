export default {
    empty: "Niente da approvare",
    saved: "Approvazioni presentate",
    review: "Si prega di rivedere e modificare il centro di costo se non è corretto",
    message: {
        message: "Nota bene: tutti gli articoli disponibili nel sito Periferiche O-I saranno addebitati in modo incrociato al tuo budget/centro di costo. In qualità di responsabile dell'approvazione, ti chiediamo di considerare attentamente ogni richiesta e di approvarla solo quando l'articolo è necessario affinché il tuo dipendente completi le proprie responsabilità lavorative."
    },
    card: {
        name: {
            label: "Nome"
        },
        date: {
            label: "Data"
        },
        details: {
            label: "Dettagli"
        },
        price: {
            label: "Prezzo"
        },
        manager: {
            label: "Manager"
        },
        requested: {
            label: "Richiesto da"
        },
        history: {
            cta: {
                load: "Approvazioni precedenti",
                loading: "Caricamento",
                show: "Approvazioni precedenti",
                hide: "Approvazioni precedenti"
            },
            approvals: {
                empty: "Nessuna approvazione preventiva",
                item: {
                    status: {
                        approver: "di {approver}",
                        system: "Servizio di assistenza",
                        approved: "Approvato",
                        rejected: "Non approvato",
                        unrequired: "Approvazione non richiesta",
                        reason: "Motivo",
                        transferred: "Trasferito"
                    }
                }
            }
        },
        reason: {
            label: "Motivo",
            placeholder: "Motivo di riempimento"
        },
        cta: {
            approve: {
                cta: "Approvare",
                processing: "Risparmio"
            },
            disapprove: {
                cta: "Non approvare",
                processing: "Risparmio"
            }
        },
        saved: "Approvazioni presentate"
    }
}