export default {
    empty: "Niets om goed te keuren",
    saved: "Goedkeuringen ingediend",
    review: "Controleer en bewerk het kostencentrum als het onjuist is",
    message: {
        message: "Let op: Alle items die beschikbaar zijn op de O-I Peripherals-site worden doorberekend aan uw budget/kostencentrum. Als goedkeurende manager vragen wij u om elk verzoek zorgvuldig te overwegen en alleen goed te keuren wanneer het item nodig is voor uw werknemer om zijn/haar taken uit te voeren."
    },
    card: {
        name: {
            label: "Naam"
        },
        date: {
            label: "Datum"
        },
        details: {
            label: "Details"
        },
        price: {
            label: "Prijs"
        },
        manager: {
            label: "Manager"
        },
        requested: {
            label: "Op verzoek van"
        },
        history: {
            cta: {
                load: "Voorafgaande goedkeuringen",
                loading: "Laden",
                show: "Voorafgaande goedkeuringen",
                hide: "Voorafgaande goedkeuringen"
            },
            approvals: {
                empty: "Geen voorafgaande goedkeuringen",
                item: {
                    status: {
                        approver: "door {approver}",
                        system: "Helpdesk",
                        approved: "Goedgekeurd",
                        rejected: "Niet goedgekeurd",
                        unrequired: "Goedkeuring niet vereist",
                        reason: "Reden",
                        transferred: "Overgedragen"
                    }
                }
            }
        },
        reason: {
            label: "Reden",
            placeholder: "Vul reden in"
        },
        cta: {
            approve: {
                cta: "Goedkeuren",
                processing: "Besparing"
            },
            disapprove: {
                cta: "Niet goedkeuren",
                processing: "Besparing"
            }
        },
        saved: "Goedkeuringen ingediend"
    }
}