<template>
    <div v-if="null !== message" class="text-center font-weight-bold pb-3 text-primary">{{message}}</div>
</template>

<script>
    export default {
        name: "ApprovalsListMessage",
        data() {

            return {
                t_path: "components.approvals.list.message"
            }
        },
        computed: {
            message() {

                const path = `${this.t_path}.message`;
                const message = this.$t(path);

                return message !== path && "" !== message ? message : null;
            }
        }
    }
</script>