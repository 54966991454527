export default {
    title: {
        new: "Ajouter une nouvelle adresse",
        complete: "Adresse complète",
        edit: "Modifier l'adresse"
    },
    incomplete: "Veuillez remplir les champs d'adresse obligatoires",
    info: {
        "CHN": "Veuillez saisir votre adresse en chinois.</br> Veuillez saisir votre adresse en chinois.",
        "BGR": "Entrez votre <b>adresse personnelle</b> en anglais pour recevoir vos articles.",
        "PRI": "Veuillez entrer votre adresse personnelle et non une boîte postale pour recevoir les colis.",
        "SGP": "Veuillez inclure votre numéro d'unité dans votre adresse.",
        "SVK": "Veuillez entrer votre adresse en <b>anglais</b>",
        "POL": "Veuillez entrer votre adresse en <b>anglais</b>"
    },
    model: {
        country: {
            label: "Pays"
        }
    },
    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nom"
                },
                "placeholder": {
                    "Name": "Nom",
                    "Name in English": "Nom en anglais"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Courriel personnel"
                },
                placeholder: {
                    "Personal Email": "Courriel personnel"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Courriel du gestionnaire"
                },
                placeholder: {
                    "Manager Email": "Courriel du gestionnaire"
                }
            },
            managerName: {
                label: {"Manager Name": "Nom du gérant"},
                placeholder: {"Manager Name": "Nom du gérant"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Code postal",
                    "Post Code": "Code postal",
                    "Postal Code": "Code Postal",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Code postal",
                    "Post Code": "Code postal",
                    "Postal Code": "Code Postal",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Province",
                    "Prefecture": "Préfecture",
                    "Province/Region": "Province/Région",
                    "Province Code": "Code de la province"
                },
                "placeholder": {
                    "Province": "Province",
                    "Prefecture": "Préfecture",
                    "Province/Region": "Province/Région",
                    "Province Code": "Code de la province"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Numéro de téléphone"
                },
                "placeholder": {
                    "Phone Number": "Numéro de téléphone"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Rue",
                    "Tax ID": "Numéro d'identification fiscale"
                },
                "placeholder": {
                    "Street": "Rue",
                    "Street in English": "Rue en anglais",
                    "Tax ID (RUT)": "Numéro d'identification fiscale (RUT)",
                    "Number/Street/Apartment": "Numéro/Rue/Appartement"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "District",
                    "Colonia": "Eau de Cologne",
                    "Canton": "Canton",
                    "Street": "Rue"
                },
                "placeholder": {
                    "District": "District",
                    "Colonia": "Eau de Cologne",
                    "Canton": "Canton",
                    "Number/Street/Apartment": "Numéro/Rue/Appartement"
                }
            },
            "city": {
                "label": {
                    "City": "Ville",
                    "Town/City/Locality": "Ville/Localité",
                    "Town/City": "Ville",
                    "City/Municipality/Locality": "Ville/Municipalité/Localité",
                    "City/Town/Locality": "Ville/Ville/Localité",
                    "City/Town": "Ville/Ville",
                    "District": "District",
                    "City/Town/Locallity": "Ville/Ville/Localité",
                    "Town/City/Sub Locality": "Ville/Ville/Sous-localité"
                },
                "placeholder": {
                    "City": "Ville",
                    "Town/City": "Ville",
                    "Town/City/Locality": "Ville/Localité",
                    "City/Municipality/Locality": "Ville/Municipalité/Localité",
                    "City/Town/Locality": "Ville/Ville/Localité",
                    "City/Town in English": "Ville/Ville en anglais",
                    "District": "District",
                    "City/Town/Locallity": "Ville/Ville/Localité",
                    "Town/City/Sub Locality": "Ville/Ville/Sous-localité"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identifiant",
                    "Identifier": "Identifiant"
                },
                placeholder: {
                    "identifier": "Identifiant",
                    "Identifier": "Identifiant"
                }
            },
            "region": {
                "label": {
                    "Region": "Région",
                    "County": "Comté",
                    "Locality": "Localité"
                },
                "placeholder": {
                    "Region": "Région",
                    "County": "Comté",
                    "Locality": "Localité"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "BOÎTE POTIQUE"
                },
                "placeholder": {
                    "PO BOX": "BOÎTE POTIQUE"
                }
            },
            state: {
                "label": {
                    "State": "État",
                    "Province/Region": "Province/Région",
                    "Province": "Province",
                    "Department": "Département",
                    "Municipality/Comuna": "Municipalité/Communauté"
                },
                "placeholder": {
                    "State": "État",
                    "Province/Region": "Province/Région",
                    "Province": "Province",
                    "Department": "Département",
                    "Municipality/Comuna": "Municipalité/Communauté"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Californie",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District fédéral de Columbia",
                    "Florida": "Floride",
                    "Georgia": "Géorgie",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiane",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "Caroline du Nord",
                    "North Dakota": "Dakota du Nord",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvanie",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Caroline du Sud",
                    "South Dakota": "Dakota du Sud",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginie",
                    "Washington": "Washington",
                    "West Virginia": "Virginie-Occidentale",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Instructions de livraison"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Informations supplémentaires sur l'adresse, instructions de livraison, etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Informations supplémentaires sur l'adresse, instructions de livraison, etc. en anglais ",
                    "Extra address information, delivery instructions, etc": "Informations supplémentaires sur l'adresse, instructions de livraison, etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Nom et prénom"},
                placeholder: {"Full name": "Nom et prénom"}
            },
            firstStreet: {
                label: {"Address 1": "Adresse 1"},
                placeholder: {"Address 1": "Rue + numéro de maison/appartement"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Adresse 2",
                    "District": "District"
                },
                placeholder: {
                    "Address 2": "Rue + numéro de maison/appartement",
                    "District": "District"
                }
            },
            city: {
                label: {City: "Ville"},
                placeholder: {City: "Ville"},
            },
            state: {
                label: {State: "État"},
                placeholder: {State: "État"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Code postal",
                    "Post Code": "Code postal",
                    "Postal Code": "Code Postal",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Code postal",
                    "Post Code": "Code postal",
                    "Postal Code": "Code Postal",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Numéro d'identification fiscale"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Tax ID (CDI, CUIT, CUIL, etc)"
                }
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "État/Territoire"},
                options: {
                    "Andaman and Nicobar Islands": "Îles Andaman et Nicobar",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra et Nagar Haveli et Daman et Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu and Kashmir": "Jammu-et-Cachemire",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kérala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Pondichéry",
                    "Punjab": "Pendjab",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "État d'Uttarakhand",
                    "West Bengal": "Bengale occidental",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} est obligatoire.",
        mask: "{field} n'est pas valide."
    },
    buttons: {
        submit: {
            text: "Sauvegarder",
            saving: "Économie"
        },
        cancel: {
            text: "Annuler"
        }
    },
    disclaimer: "L'ajout de cette adresse nécessitera une vérification par e-mail. Après avoir enregistré l'adresse, veuillez vérifier votre e-mail et cliquer sur le lien à l'intérieur.",
    message: {
        added: "L'adresse a été ajoutée",
        updated: "L'adresse a été mise à jour"
    }
}