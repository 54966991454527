export default {
    empty: "沒有需要批准的",
    saved: "已提交批准",
    review: "如果不正确，请检查并编辑成本中心",
    message: {
        message: "请注意：O-I 外围设备站点中提供的所有项目都将从您的预算/成本中心交叉收费。作为审批经理，我们要求您仔细考虑每个请求，并且仅在该项目对您的员工完成其工作职责是必需时才批准。"
    },
    card: {
        name: {
            label: "姓名"
        },
        date: {
            label: "日期"
        },
        details: {
            label: "细节"
        },
        price: {
            label: "价格"
        },
        manager: {
            label: "经理"
        },
        requested: {
            label: "请求者"
        },
        history: {
            cta: {
                load: "事先批准",
                loading: "加载中",
                show: "事先批准",
                hide: "事先批准"
            },
            approvals: {
                empty: "无需事先批准",
                item: {
                    status: {
                        approver: "来自 {approver}",
                        system: "服务台",
                        approved: "得到正式认可的",
                        rejected: "未批准",
                        unrequired: "无需批准",
                        reason: "原因",
                        transferred: "已转移"
                    }
                }
            }
        },
        reason: {
            label: "原因",
            placeholder: "填写原因"
        },
        cta: {
            approve: {
                cta: "批准",
                processing: "保存"
            },
            disapprove: {
                cta: "不同意",
                processing: "保存"
            }
        },
        saved: "已提交批准"
    }
}