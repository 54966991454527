export default {
    title: "代订",
    model: {
        email: {
            label: "电子邮件",
            placeholder: "请填写用户邮箱",
            errors: {
                myself: "电子邮件必须与您的不同"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "提交",
            processing: "提交"
        }
    },
    error: {
        userNotExists: "{email} 不存在",
        country: {
            default: "OI 目前在用户所在国家/地区不可用",
            ALPHA3: ""
        }
    }
}