export default {
    title: "Objednávejte jménem",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Vyplňte prosím e-mail uživatele",
            errors: {
                myself: "E-mail se musí lišit od vašeho"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Předložit",
            processing: "Odesílání"
        }
    },
    error: {
        userNotExists: "{email} neexistuje",
        country: {
            default: "OI je momentálně v zemi uživatele nedostupný",
            ALPHA3: ""
        }
    }
}