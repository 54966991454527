export default {
    title: {
        new: "Nieuw adres toevoegen",
        complete: "Volledig adres",
        edit: "Adres bewerken"
    },
    incomplete: "Vul de verplichte adresvelden in",
    info: {
        "CHN": "Voer uw adres in het Chinees in.</br> Voer uw adres in het Chinees in.",
        "BGR": "Voer uw <b>huisadres</b> in het Engels in om uw artikelen te ontvangen.",
        "PRI": "Voer uw huisadres in en geen postbus om pakketten te ontvangen.",
        "SGP": "Vermeld het nummer van uw unit in uw adres.",
        "SVK": "Voer uw adres in <b>het Engels in</b>",
        "POL": "Voer uw adres in <b>het Engels in</b>"
    },
    model: {
        country: {
            label: "Land"
        }
    },
    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Naam"
                },
                "placeholder": {
                    "Name": "Naam",
                    "Name in English": "Naam in het Engels"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Persoonlijk e-mailadres"
                },
                placeholder: {
                    "Personal Email": "Persoonlijk e-mailadres"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-mailadres van de manager"
                },
                placeholder: {
                    "Manager Email": "E-mailadres van de manager"
                }
            },
            managerName: {
                label: {"Manager Name": "Naam van de manager"},
                placeholder: {"Manager Name": "Naam van de manager"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincie",
                    "Prefecture": "Prefectuur",
                    "Province/Region": "Provincie/Regio",
                    "Province Code": "Provinciecode"
                },
                "placeholder": {
                    "Province": "Provincie",
                    "Prefecture": "Prefectuur",
                    "Province/Region": "Provincie/Regio",
                    "Province Code": "Provinciecode"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Telefoonnummer"
                },
                "placeholder": {
                    "Phone Number": "Telefoonnummer"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Straat",
                    "Tax ID": "Belasting-ID"
                },
                "placeholder": {
                    "Street": "Straat",
                    "Street in English": "Straat in het Engels",
                    "Tax ID (RUT)": "Belastingnummer (RUT)",
                    "Number/Street/Apartment": "Nummer/Straat/Appartement"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Wijk",
                    "Colonia": "Keulen",
                    "Canton": "Kanton",
                    "Street": "Straat"
                },
                "placeholder": {
                    "District": "Wijk",
                    "Colonia": "Keulen",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Nummer/Straat/Appartement"
                }
            },
            "city": {
                "label": {
                    "City": "Stad",
                    "Town/City/Locality": "Stad/stad/plaats",
                    "Town/City": "Stad/stad",
                    "City/Municipality/Locality": "Stad/Gemeente/Plaats",
                    "City/Town/Locality": "Stad/dorp/plaats",
                    "City/Town": "Stad/dorp",
                    "District": "Wijk",
                    "City/Town/Locallity": "Stad/dorp/plaats",
                    "Town/City/Sub Locality": "Stad/stad/subregio"
                },
                "placeholder": {
                    "City": "Stad",
                    "Town/City": "Stad/stad",
                    "Town/City/Locality": "Stad/stad/plaats",
                    "City/Municipality/Locality": "Stad/Gemeente/Plaats",
                    "City/Town/Locality": "Stad/dorp/plaats",
                    "City/Town in English": "Stad/dorp in het Engels",
                    "District": "Wijk",
                    "City/Town/Locallity": "Stad/dorp/plaats",
                    "Town/City/Sub Locality": "Stad/stad/subregio"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificatie",
                    "Identifier": "Identificatie"
                },
                placeholder: {
                    "identifier": "Identificatie",
                    "Identifier": "Identificatie"
                }
            },
            "region": {
                "label": {
                    "Region": "Regio",
                    "County": "District",
                    "Locality": "Plaats"
                },
                "placeholder": {
                    "Region": "Regio",
                    "County": "District",
                    "Locality": "Plaats"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "POSTBUS"
                },
                "placeholder": {
                    "PO BOX": "POSTBUS"
                }
            },
            state: {
                "label": {
                    "State": "Staat",
                    "Province/Region": "Provincie/Regio",
                    "Province": "Provincie",
                    "Department": "Afdeling",
                    "Municipality/Comuna": "Gemeente/Comuna"
                },
                "placeholder": {
                    "State": "Staat",
                    "Province/Region": "Provincie/Regio",
                    "Province": "Provincie",
                    "Department": "Afdeling",
                    "Municipality/Comuna": "Gemeente/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Californië",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District van Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgië",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "North Carolina",
                    "North Dakota": "North Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode-eiland",
                    "South Carolina": "South Carolina",
                    "South Dakota": "South Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Bezorginstructies"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Extra adresgegevens, bezorginstructies, etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Extra adresgegevens, bezorginstructies, etc. in het Engels ",
                    "Extra address information, delivery instructions, etc": "Extra adresgegevens, bezorginstructies, etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Volledige naam"},
                placeholder: {"Full name": "Volledige naam"}
            },
            firstStreet: {
                label: {"Address 1": "Adres 1"},
                placeholder: {"Address 1": "Straat + huis-/appartementnummer"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Adres 2",
                    "District": "Wijk"
                },
                placeholder: {
                    "Address 2": "Straat + huis-/appartementnummer",
                    "District": "Wijk"
                }
            },
            city: {
                label: {City: "Stad"},
                placeholder: {City: "Stad"},
            },
            state: {
                label: {State: "Staat"},
                placeholder: {State: "Staat"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Belasting-ID"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Belastingnummer (CDI, CUIT, CUIL, etc.)"
                }
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "Staat/Territorium"},
                options: {
                    "Andaman and Nicobar Islands": "Andamanen en Nicobaren",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra en Nagar Haveli en Daman en Diu",
                    "Delhi": "Delhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal-Pradesh",
                    "Jammu and Kashmir": "Jammu en Kasjmir",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Kerala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagaland",
                    "Odisha": "Odisha",
                    "Puducherry": "Pondicherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajasthan",
                    "Sikkim": "Sikkim",
                    "Tamil Nadu": "Tamil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Uttar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "West-Bengalen",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} is verplicht.",
        mask: "{field} is ongeldig."
    },
    buttons: {
        submit: {
            text: "Redden",
            saving: "Besparing"
        },
        cancel: {
            text: "Annuleren"
        }
    },
    disclaimer: "Om dit adres toe te voegen is e-mailverificatie vereist. Controleer na het opslaan van het adres uw e-mail en klik op de link erin.",
    message: {
        added: "Adres is toegevoegd",
        updated: "Adres is bijgewerkt"
    }
}