<template>
    <div class="approvals-list">

        <div v-if="0 < items.length">
            <approvals-list-message/>

            <div v-if="review" class="form-group text-center pb-3">{{review}}</div>

            <div class="row">
                <approvals-list-card v-for="(approval, index) in items"
                                     :key="'card_' + index"
                                     :approval="approval"
                                     :config="config"
                                     :editable-fields="editableFields"
                                     :static-fields="staticFields"
                                     @refresh="$emit('refresh')"/>
            </div>
        </div>

        <div v-else-if="0 === items.length" class="text-center">
            <div class="pb-3">{{t('empty')}}</div>

            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </div>
    </div>
</template>

<script>

    import {get} from "lodash";

    import ApprovalsListCard from "@/components/approvals/list/ApprovalsListCard";
    import ApprovalsListMessage from "@/components/approvals/list/ApprovalsListMessage";
    import Spinner from "@/components/Spinner";

    const TRANSLATIONS = ["label", "placeholder"]; // Field properties to translate

    export default {
        name: "ApprovalsList",
        emits: ["refresh"],
        components: {
            ApprovalsListCard,
            ApprovalsListMessage,
            Spinner
        },
        props: {
            approvals: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list",
                processing: false,
                items: []
            };
        },
        computed: {
            config() {

                return this.$store.getters["config/user/approvals/config"].map(
                    field => {

                        // Handle translations
                        TRANSLATIONS
                            .filter(property => true === this.$te(`approval_config.${field.payload.field}.${property}`))
                            .forEach(property => field[property] = this.$t(`approval_config.${field.payload.field}.${property}`));

                        // Handle errors and translations
                        field.errors = Object
                            .keys("undefined" !== typeof field.validation ? field.validation : {})
                            .filter(property => true === this.$te(`approval_config.${field.payload.field}.errors.${property}`))
                            .reduce(
                                (errors, property) => ({
                                    ...errors,
                                    [property]: this.$t(`approval_config.${field.payload.field}.errors.${property}`)
                                }),
                                {}
                            );

                        return field;
                    }
                );
            },
            editableFields() {

                return this.config.filter(field => true === field.editable);
            },
            staticFields() {

                return this.config.filter(field => true !== field.editable);
            },
            review() {

                return this.t("review");
            }
        },
        methods: {
            init() {

                this.items.splice(
                    0,
                    this.items.length,
                    ...this.approvals
                        .reduce(
                            (result, item) => {

                                result.push({
                                    order: {
                                        id: item.order_id,
                                        cost: item.cost,
                                        currency: item.currency,
                                        country: item.country,
                                        date: new Date(item.date),
                                        lang_overrides: item.lang_overrides,
                                        details: item.details,
                                        requested_by: get(item, "requested_by", null)
                                    },
                                    transfer: {
                                        enabled: false
                                    },
                                    user: {
                                        id: item.user_id,
                                        name: item.name,
                                    },
                                    approval: {
                                        required: true !== item.approval_not_required,
                                        reason: get(item, "approval.approval_reason", null),
                                        manager: {
                                            email: get(item, "approval.assigned_approver")
                                        },
                                        approver: {
                                            // Nobody is here because order is pending
                                            email: null
                                        }
                                    },
                                    dynamic: {
                                        ...this.config.reduce(
                                            (result, field) => ({
                                                ...result,
                                                [field.payload.field]: item[field.payload.field]
                                            }),
                                            {}
                                        )
                                    },
                                    model: {
                                        valid: true, // By default, valid is true
                                        approved: null,
                                        reason: null,
                                        // Append dynamic fields
                                        ...this.config.reduce(
                                            (result, field) => {

                                                if (true === field.editable || "undefined" !== typeof field.watch) {

                                                    result[field.payload.field] = item[field.payload.field];
                                                }

                                                return result;
                                            },
                                            {}
                                        )
                                    }
                                });

                                return result;
                            },
                            []
                        )
                        .sort((a, b) => a.order.date.getTime() - b.order.date.getTime())
                );
            }
        },
        mounted() {

            this.init();
        }
    }
</script>