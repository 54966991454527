export default {
    empty: "Nic ke schválení",
    saved: "Schválení předloženo",
    review: "Pokud jsou nesprávné, zkontrolujte a upravte nákladové středisko",
    message: {
        message: "Poznámka: Všechny položky dostupné v O-I Web periferií bude křížově účtován vašemu rozpočtu/nákladovému středisku. Jako schvalujícího manažera vás žádáme, abyste pečlivě zvážili každou žádost a schválili ji pouze tehdy, když je položka nezbytná k tomu, aby váš zaměstnanec splnil své pracovní povinnosti."
    },
    card: {
        name: {
            label: "Jméno"
        },
        date: {
            label: "Datum"
        },
        details: {
            label: "Podrobnosti"
        },
        price: {
            label: "Cena"
        },
        manager: {
            label: "Manažer"
        },
        requested: {
            label: "Vyžádáno uživatelem"
        },
        history: {
            cta: {
                load: "Předchozí schválení",
                loading: "Načítání",
                show: "Předchozí schválení",
                hide: "Předchozí schválení"
            },
            approvals: {
                empty: "Žádná předchozí schválení",
                item: {
                    status: {
                        approver: "od {approver}",
                        system: "Help Desk",
                        approved: "Schválený",
                        rejected: "Neschváleno",
                        unrequired: "Schválení není vyžadováno",
                        reason: "Důvod",
                        transferred: "Převedeno"
                    }
                }
            }
        },
        reason: {
            label: "Důvod",
            placeholder: "Vyplňte důvod"
        },
        cta: {
            approve: {
                cta: "Schvalovat",
                processing: "Ukládání"
            },
            disapprove: {
                cta: "Neschválit",
                processing: "Ukládání"
            }
        },
        saved: "Schválení předloženo"
    }
}