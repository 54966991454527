<template>
    <approvals-list-card-section v-if="null !== manager" :label="t('label')" :text="manager" :css-class="cssClass"/>
</template>

<script>

    import {get} from "lodash";

    import ApprovalsListCardSection from "@/components/approvals/list/card/ApprovalsListCardSection";

    export default {
        name: "ApprovalsListCardManager",
        components: {
            ApprovalsListCardSection
        },
        props: {
            approval: {
                type: Object,
                required: true
            },
            cssClass: {
                type: String,
                default: () => "col-12"
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.card.manager"
            };
        },
        computed: {
            manager() {

                const email = get(this.approval, "approval.manager.email")
                    ? get(this.approval, "approval.manager.email").toLowerCase()
                    : "";

                if ("" !== email && this.$store.getters["user/profile/email"].toLowerCase() !== email) {

                    return email;
                }

                return null;
            }
        }
    }
</script>