<template>
    <div class="pb-5 col-12">
        <panel>
            <div class="row">
                <approvals-list-card-name :approval="approval"/>

                <approvals-list-card-date :approval="approval"/>

                <approvals-list-card-history-approvals-card-status :approval="approval"/>

                <approvals-list-card-details :approval="approval"/>

                <approvals-list-card-price :approval="approval"/>

                <approvals-list-card-requested :approval="approval"/>

                <approvals-list-card-manager :approval="approval"/>

                <approvals-list-card-history-approvals-card-fields :approval="approval" :config="config"/>
            </div>
        </panel>
    </div>
</template>

<script>

    import ApprovalsListCardDate from "@/components/approvals/list/card/ApprovalsListCardDate";
    import ApprovalsListCardDetails from "@/components/approvals/list/card/ApprovalsListCardDetails";
    import ApprovalsListCardHistoryApprovalsCardFields from "@/components/approvals/list/card/history/ApprovalsListCardHistoryApprovalsCardFields";
    import ApprovalsListCardHistoryApprovalsCardStatus from "@/components/approvals/list/card/history/ApprovalsListCardHistoryApprovalsCardStatus";
    import ApprovalsListCardManager from "@/components/approvals/list/card/ApprovalsListCardManager";
    import ApprovalsListCardName from "@/components/approvals/list/card/ApprovalsListCardName";
    import ApprovalsListCardPrice from "@/components/approvals/list/card/ApprovalsListCardPrice";
    import ApprovalsListCardRequested from "@/components/approvals/list/card/ApprovalsListCardRequested";
    import Panel from "@/components/Panel";

    export default {
        name: "ApprovalsListCardHistoryApprovalsCard" ,
        components: {
            ApprovalsListCardDate,
            ApprovalsListCardDetails,
            ApprovalsListCardHistoryApprovalsCardFields,
            ApprovalsListCardHistoryApprovalsCardStatus,
            ApprovalsListCardManager,
            ApprovalsListCardName,
            ApprovalsListCardPrice,
            ApprovalsListCardRequested,
            Panel
        },
        props: {
            config: {
                type: Array,
                required: true
            },
            approval: {
                type: Object,
                required: true
            }
        }
    }
</script>