<template>
    <div class="approvals-list-card-history-approvals">
        <div v-if="0 === approvals.length" class="no-approvals">{{t('empty')}}</div>

        <div class="row">
            <approvals-list-card-history-approvals-card v-for="approval in approvals"
                                                        :approval="approval"
                                                        :config="config"/>
        </div>
    </div>
</template>

<script>

    import ApprovalsListCardHistoryApprovalsCard from "@/components/approvals/list/card/history/ApprovalsListCardHistoryApprovalsCard";

    export default {
        name: "ApprovalsListCardHistoryApprovals",
        components: {
            ApprovalsListCardHistoryApprovalsCard
        },
        props: {
            approvals: {
                type: Array,
                required: true
            },
            config: {
                type: Array,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.approvals.list.card.history.approvals"
            };
        }
    }
</script>