export default {
    required: "Se requiere un valor",
    email: "El valor no es una dirección de correo electrónico válida",
    mask: "El valor no es válido",
    maxValue: "El valor máximo es {maxValue}",
    regex: "El valor no es válido",
    server: {
        DELEGATES_NOT_ENABLED: "Los delegados no están habilitados para este socio.",
        DELEGATE_DOES_NOT_EXIST: "El delegado no existe",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "No se puede actualizar un delegado que no le pertenece.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "No se puede eliminar un delegado que no le pertenece.",
        DELEGATE_ALREADY_EXISTS: "Ya existe una regla de delegado que coincide con este criterio.",
        DELEGATE_CANNOT_BE_SELF: "No puedes agregarte como delegado.",
        IMPERSONATE_USER_NO_PERMISSIONS: "El usuario {email} no tiene acceso a DXC Gear y no se pueden realizar pedidos en su nombre",
        INVALID_USER_TYPE: "El tipo de usuario no es un empleado",
        INVALID_EMPLOYMENT_STATUS: "El usuario no es un empleado activo",
        USER_NOT_FOUND: "Usuario no encontrado",
        "Invalid user type": "Tipo de usuario no válido",
        "User not found": "Usuario no encontrado"
    }
}