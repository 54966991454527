export default {
    title: "Overdrachtsproduct",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Vul e-mail in",
            errors: {
                myself: "E-mailadres moet verschillen van uw"
            }
        },
        reason: {
            label: "Reden",
            placeholder: "Reden"
        }
    },
    message: "De ontvanger ontvangt een e-mail waarin hij/zij op de hoogte wordt gesteld van de overdracht.",
    saved: "Opgeslagen",
    cta: {
        submit: {
            text: "Indienen",
            processing: "Verzenden"
        },
        cancel: {
            text: "Annuleren"
        }
    }
}