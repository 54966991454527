export default {
    empty: "Rien à approuver",
    saved: "Approbations soumises",
    review: "Veuillez vérifier et modifier le centre de coûts s'il est incorrect.",
    message: {
        message: "Remarque : tous les articles disponibles sur le site O-I Périphériques seront imputés à votre budget/centre de coûts. En tant que responsable d'approbation, nous vous demandons d'examiner attentivement chaque demande et de n'approuver que lorsque l'article est nécessaire à votre employé pour s'acquitter de ses responsabilités professionnelles."
    },
    card: {
        name: {
            label: "Nom"
        },
        date: {
            label: "Date"
        },
        details: {
            label: "Détails"
        },
        price: {
            label: "Prix"
        },
        manager: {
            label: "Directeur"
        },
        requested: {
            label: "Demandé par"
        },
        history: {
            cta: {
                load: "Approbations préalables",
                loading: "Chargement",
                show: "Approbations préalables",
                hide: "Approbations préalables"
            },
            approvals: {
                empty: "Aucune approbation préalable",
                item: {
                    status: {
                        approver: "par {approver}",
                        system: "Service d'assistance",
                        approved: "Approuvé",
                        rejected: "Non approuvé",
                        unrequired: "Approbation non requise",
                        reason: "Raison",
                        transferred: "Transféré"
                    }
                }
            }
        },
        reason: {
            label: "Raison",
            placeholder: "Raison de remplissage"
        },
        cta: {
            approve: {
                cta: "Approuver",
                processing: "Économie"
            },
            disapprove: {
                cta: "Ne pas approuver",
                processing: "Économie"
            }
        },
        saved: "Approbations soumises"
    }
}