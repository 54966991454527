import mixin_constraints from "@/mixins/constraints";

export default {
    mixins: [mixin_constraints],
    data() {

        return {
            catalog_empty: {}
        };
    },
    methods: {
        catalog_getCategories() {

            const isFilterEmptyEnabled = 1 === parseInt(process.env.VUE_APP_CATALOG_FILTER_EMPTY_GROUPINGS);

            return true === isFilterEmptyEnabled
                ? this.$store.getters["grouping/items"].filter(grouping => false === this.catalog_empty[grouping.id])
                : this.$store.getters["grouping/items"];
        },
        catalog_fetchCategories({type, activeId}) {

            const isFilterEmptyEnabled = 1 === parseInt(process.env.VUE_APP_CATALOG_FILTER_EMPTY_GROUPINGS);

            return this
                .$store
                .dispatch("grouping/fetch", {type, activeId})
                .then(
                    () => Promise.all(
                        true === isFilterEmptyEnabled
                            ? this.$store.getters["grouping/items"].map(
                                grouping => this.$platform.get(
                                    `/api/v2/grouping/${type}/${grouping.id}`
                                )
                            )
                            : []
                    )
                )
                .then(
                    responses => this.catalog_empty = responses.reduce(
                        (emtpy, response) => ({
                            ...emtpy,
                            [response.data.results._id]: 0 === this.constraints_filterAvailableProducts(response.data.results.products).length
                        }),
                        {}
                    )
                )
        },
        catalog_logActivity() {

            const categories = this.$store.getters["grouping/items"];
            const constraints = this.$store.getters["user/constraints/type"];

            if (0 === categories.length) {

                this.$activity.log("catalog_no_groupings", {constraints});
                this.$activity.log("catalog_no_products", {constraints});
            } else {

                const isFilterEmptyEnabled = 1 === parseInt(process.env.VUE_APP_CATALOG_FILTER_EMPTY_GROUPINGS);

                const request = {
                    constraints,
                    groupings: categories.map(grouping => ({name: grouping.name, id: grouping.id})),
                }

                if (true === isFilterEmptyEnabled) {

                    request["groupings_visible"] = this
                        .catalog_getCategories()
                        .map(grouping => ({name: grouping.name, id: grouping.id}))
                }

                this.$activity.log("catalog_groupings", request);
            }
        }
    }
}